<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>Add Page</h5>
        </div>
        <div class="card-body tab2-card">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                  <a ngbNavLink>General</a>
                  <ng-template ngbNavContent>
                    <form [formGroup]="generalForm"  class="needs-validation" novalidate="">
                        <h4>General</h4>
                        <div class="form-group row align-items-center">
                            <div class="col-xl-3 col-md-4"><label for="validationCustom0"><span>*</span> Name</label></div>
                            <div class="col-xl-8 col-md-7"><input class="form-control" formControlName="name"
                                id="validationCustom0" type="text" required="">
                            </div>
                        </div>
                        <div class="form-group row editor-label align-items-center">
                            <div class="col-xl-3 col-md-4"><label>Description</label></div>
                            <div class="col-xl-8 col-md-7"><textarea class="w-100" rows="4" formControlName="desc"></textarea></div>
                        </div>
                        <div class="form-group row">
                            <div class="col-xl-3 col-md-4"><label>Status</label></div>
                            <div class="col-xl-8 col-md-7"><div class="checkbox checkbox-primary">
                                <input id="checkbox-primary-2" formControlName="status" type="checkbox"
                                    data-original-title="" title="">
                                <label for="checkbox-primary-2">Enable the Page</label>
                            </div>
                        </div> 
                        </div>
                    </form>
                  </ng-template>
                </li>
                <li [ngbNavItem]="2">
                  <a ngbNavLink>SEO</a>
                  <ng-template ngbNavContent>
                    <form [formGroup]="seoForm" class="needs-validation" novalidate="">
                        <h4>SEO</h4>
                        <div class="form-group row">
                            <div class="col-xl-3 col-md-4"><label for="validationCustom2">Meta Title</label></div>
                            <div class="col-xl-8 col-md-7"><input class="form-control" formControlName="title"
                                id="validationCustom2" type="text" required=""></div>
                        </div>
                        <div class="form-group row align-items-center editor-label">
                            <div class="col-xl-3 col-md-4"><label>Meta Description</label></div>
                            <div class="col-xl-8 col-md-7"><textarea rows="4" class="w-100" formControlName="meta_desc"></textarea></div>
                        </div>
                    </form>
                  </ng-template>
                </li>
                <!-- <li [ngbNavItem]="3">
                    <a ngbNavLink>Usage</a>
                    <ng-template ngbNavContent>
                        <form [formGroup]="usageForm" class="needs-validation" novalidate="">
                            <h4>Usage Limits</h4>
                            <div class="form-group row">
                                <label for="validationCustom6" class="col-xl-3 col-md-4">Per Limit</label>
                                <input class="form-control col-md-7" formControlName="limit" id="validationCustom6"
                                    type="number">
                            </div>
                            <div class="form-group row">
                                <label for="validationCustom7" class="col-xl-3 col-md-4">Per Customer</label>
                                <input class="form-control col-md-7" formControlName="customer" id="validationCustom7"
                                    type="number">
                            </div>
                        </form>
                    </ng-template>
                  </li> -->
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
            <div class="pull-right">
                <button type="button" class="btn btn-primary">Save</button>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->