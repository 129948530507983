import { Component } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {

  registerForm: FormGroup;
  isPasswordVisible: boolean = false;
  isconfirmVisible: boolean = false;


  constructor(private fb: FormBuilder,private router: Router) {
    this.registerForm = this.fb.group({
      FullName: ['', Validators.required],
      PhoneNo: ['', [Validators.required, Validators.pattern('^\\d{10}$')]],
      Email: ['', [Validators.required, Validators.email]],
      Password: ['', [Validators.required, Validators.minLength(6)]],
      ConfirmPassword: ['', Validators.required],
    }, { validators: this.passwordMatchValidator });
  }

  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  toggleconfirmVisibility(): void {
    this.isconfirmVisible = !this.isconfirmVisible;
  }

  passwordMatchValidator(group: FormGroup): { [key: string]: boolean } | null {
    const password = group.get('Password')?.value;
    const confirmPassword = group.get('ConfirmPassword')?.value;
    return password === confirmPassword ? null : { notMatching: true };
  }

  onSubmit(): void {
    if (this.registerForm.valid) {
      console.log(this.registerForm.value);

      this.router.navigate(['/login']);

    } else {
      this.registerForm.markAllAsTouched();
    }
  }

}




