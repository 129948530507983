<div class="unauthorized-container vh-100 " style="    align-content: space-evenly;">

    <div>
        <img src="assets/images/error.jpg" class="mr-2 w-50" alt="Clean UI" />
    </div>
    <h1>Unauthorized</h1>
    <p style="    font-family: serif;">You do not have permission to view this page.</p>
    <!-- <a routerLink="/home">Go to Home</a> -->
    <button type="button" class="btn btn-primary" (click)="Lock()"> <i class="fa fa-arrow-left"
            aria-hidden="true"></i><span class="ml-2">Go Back</span> </button>
</div>