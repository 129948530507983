import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class MyService {

  public UserSetting: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  

  public User: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  public Usersetparameter: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  public storeId: BehaviorSubject<number> = new BehaviorSubject<number>(0)

  public contactId: BehaviorSubject<number> = new BehaviorSubject<number>(0)


  constructor() {
  }


}