<div class="page-main-header" [class.open]="open">
    <div class="main-header-right row">
        <div class="main-header-left d-lg-none col">
            <div class="logo-wrapper d-block"><a [routerLink]="'/dashboard/default'"><img class="blur-up lazyloaded"
                        style="height: 30px;" src="https://bizdomfs.blob.core.windows.net/post-image/52278575-c1ae-434f-a484-857104cee3ae.png" alt=""></a> 
                <div class="biz"> FB-Admin</div>

            </div>
        </div>
        <div class="mobile-sidebar col">
            <div class="media-body switch-sm">
                <label class="switch">
                    <a>
                        <app-feather-icons id="sidebar-toggle" [icon]="'align-left'" (click)="collapseSidebar()">
                        </app-feather-icons>
                    </a>
                </label>
            </div>
        </div>
        <div class="nav-right col">
            <ul class="nav-menus" [ngClass]="{'open' : openNav}">
                <li>
                    <form class="form-inline search-form">
                        <!-- <div class="form-group">
                            <input class="form-control-plaintext" [class.open]="isOpenMobile" type="search"
                                placeholder="Search..">
                            <span class="d-sm-none mobile-search">
                                <app-feather-icons [icon]="'search'" (click)="isOpenMobile=!isOpenMobile">
                                </app-feather-icons>
                            </span>
                        </div> -->
                    </form>
                </li>
                <li>
                    <a href="javascript: void(0);" (click)="stopnotificationsound()" class="text-dark">
                        <!-- <app-feather-icons [icon]="'maximize-2'"></app-feather-icons> -->
                        <i style="color: #ff8084;font-size: 20px; 
                    " class="fa fa-bell-slash-o" aria-hidden="true" ngbTooltip="Mute Sound"></i>
                    </a>
                </li>
            
<!-- 
                <li class="onhover-dropdown">
                    <span class="badge badge-pill badge-primary pull-right notification-badge">3</span>
                    <app-feather-icons [icon]="'bell'"></app-feather-icons><span class="dot"></span>
                    <ul class="notification-dropdown onhover-show-div">
                        <li>Notification <span class="badge badge-pill badge-primary pull-right">3</span></li>
                        <li>
                            <div class="media">
                                <div class="media-body">
                                    <h6 class="mt-0"><span>
                                            <app-feather-icons class="shopping-color" [icon]="'shopping-bag'">
                                            </app-feather-icons>
                                        </span>Your order ready for Ship..!
                                    </h6>
                                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="media">
                                <div class="media-body">
                                    <h6 class="mt-0 txt-success"><span>
                                            <app-feather-icons class="download-color font-success" [icon]="'download'">
                                            </app-feather-icons>
                                        </span>Download Complete</h6>
                                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="media">
                                <div class="media-body">
                                    <h6 class="mt-0 txt-danger"><span>
                                            <app-feather-icons class="alert-color font-danger" [icon]="'alert-circle'">
                                            </app-feather-icons>
                                        </span>250 MB trash files</h6>
                                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetuer.</p>
                                </div>
                            </div>
                        </li>
                        <li class="txt-dark"><a href="javascript:void(0)">All</a> notification</li>
                    </ul>
                </li> -->
            

                <li class="onhover-dropdown">
                    <div class="media align-items-center"><img
                            class="align-self-center pull-right img-50 rounded-circle"
                            src="https://i.pinimg.com/736x/ff/82/b6/ff82b607537ed90b2f343c643960acfa.jpg" alt="header-user">

                        <!-- <div class="dotted-animation"><span class="animate-circle"></span><span
                                class="main-circle"></span></div> -->
                    </div>
                    <ul class="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                     
                        <li><a (click)="Lock()">
                                <app-feather-icons [icon]="'lock'"></app-feather-icons>Lock Screen
                            </a></li>
                        <li>
                            <a (click)="Logout()">
                                <app-feather-icons [icon]="'log-out'"></app-feather-icons>Logout
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
                <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
            </div>
        </div>
    </div>
</div>