import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { content } from './shared/routes/content-routes';
import { ContentLayoutComponent } from './shared/layout/content-layout/content-layout.component';
import { LoginComponent } from './components/auth/login/login.component';
import { UnauthroizedComponent } from './components/auth/unauthroized/unauthroized.component';
import { PinscreenComponent } from './components/auth/pinscreen/pinscreen.component';
import { AuthGuardService } from './authgurd.service';

const routes: Routes = [

  { path: 'unauthorized', component: UnauthroizedComponent },
  // {
  //   path: '',
  //   redirectTo: 'dashboard/default',    
  //   pathMatch: 'full',
  //   data: { title: 'PlaceOrder App', pageid: 1 },
  // },
  {
    path: '',
    data: {
      title: "Ecom Dashboard",
      pageid: 2
    },
    component: ContentLayoutComponent,
    canActivate: [AuthGuardService],
    children: content
  },
  {
    path: 'auth/login',
    component: LoginComponent,
  },

  {
    path: 'auth/pin',
    component: PinscreenComponent,
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
