<div class="page-wrapper">
    <div class="imagebox">
        <div class="authentication-box" style="backdrop-filter: blur(3px);">
            <div class="container">
                <div class="row" style="justify-content: center;">
                    <div class="col-md-7 p-0 card-right">
                        <div class="card tab2-card">
                            <div class="card-body">
                                <div class="text-center">
                                    <h2 style="font-size: 26px;">PinScreen</h2>
                                </div>
                                <form [formGroup]="pinScreen" (ngSubmit)="verifyPin()"
                                    class="form-horizontal auth-form mt-4" novalidate="">
                                    <div class="form-group">
                        

                                          <label for="" class="f-18">Enter Pin:</label>
                                        <input type="password" formControlName="pin" class="form-control form-control-lg"
       required minlength="4" maxlength="6" pattern="[0-9]*" inputmode="numeric"
       (keypress)="preventNonNumeric($event)" />
       
                                

                                        <div style="text-align: justify;"
                                            *ngIf="pinScreen.get('pin')?.invalid && (pinScreen.get('pin')?.dirty || pinScreen.get('pin')?.touched)"
                                            class="text-danger">
                                            <div *ngIf="pinScreen.get('pin')?.errors?.['required']">Pin is required.
                                            </div>
                                            <div *ngIf="pinScreen.get('pin')?.errors?.['minlength']">Pin must be at
                                                least 4
                                                characters long.</div>
                                        </div>
                                    </div>
                                    <div class="form-button text-center">
                                        <a> <button class="btn btn-primary" type="submit">
                                                Unlock Account</button></a>
                                    </div>
                                    <hr>
                                    <div class="text-center">
                                        <span class="mt-4">Don't have an account? <a [routerLink]="'/login'">Sign In
                                            </a> </span>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>


<div style="display: none;    z-index: 999;
    position: fixed;" id="logload">
    <div class="bouncing-loader">
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>