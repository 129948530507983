<div class="container-fluid">
    <div class="page-header">
      <div class="row">
        <div class="col-lg-6">
          <div class="page-header-left">
            <h3>
              COMPANY - WISE REPORT
    
            </h3>
          </div>
        </div>
      
      </div>
    </div>
  </div>

<div class="container-fluid">
    <div class="row">

        <div class="card" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
            <div class="card-content">
                <div class="card-body">
                    <div class="row">
                        <div class="col-3">
                            <h5><strong>From Date - To Date</strong> </h5>
                            <div class="form-group position-relative">
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Select Date"
                                        id="datePickerInput" (click)="openDatePicker()" [value]="
                            (fromDate ? formatDate(fromDate) : '') +
                            (fromDate ? ' / ' : '') +
                            (toDate ? formatDate(toDate) : '')
                          " autocomplete="off" />
                                    <div class="input-group-append">
                                        <button class="btn btn-primary" type="button" (click)="openDatePicker()"
                                            *ngIf="isopen">
                                            <i class="fa fa-calendar" aria-hidden="true"></i>
                                        </button>
                                        <button class="btn btn-primary" type="button" (click)="clearval()"
                                            *ngIf="!isopen">
                                            <i class="fa fa-times" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                                <div *ngIf="datePickerOpened" class="datepicker-container-s border rounded">
                                    <ngb-datepicker #dp1 ngModel (ngModelChange)="onDateChange($event)"
                                        [displayMonths]="2" [dayTemplate]="t">
                                    </ngb-datepicker>

                                    <!-- Today, Yesterday, Last 7 days buttons -->
                                    <div class="text-center mt-2 mb-2">
                                        <button class="btn btn-primary mr-2" type="button" (click)="selectToday()"
                                            style="margin: 6px;">
                                            Today
                                        </button>
                                        <button class="btn btn-primary mr-2" type="button"
                                            (click)="selectYesterday()" style="margin: 6px;">
                                            Yesterday
                                        </button>
                                        <button class="btn btn-primary" type="button" (click)="selectLast7Days()"
                                            style="margin: 6px;">
                                            Last 7 days
                                        </button>
                                    </div>


                                    <ng-template #t let-date="date" let-focused="focused">
                                        <span class="custom-day" [class.focused]="focused" [class.range]="
    isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
                                            [class.faded]="isHovered(date) || isInside(date)"
                                            (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null"
                                            (dblclick)="setFromDateToDate(date)">
                                            {{ date.day }}
                                        </span>
                                    </ng-template>
                                </div>
                            </div>

                        </div>



                        <div class="col-3">
                            <h5>Company</h5>
                            <select class="custom-select form-select" required [(ngModel)]="companyId" >
                                <option value="0">--All--</option>
                                <option [value]="company.id" *ngFor="let company of companies">{{company.name}}</option>
                                
                            </select>

                        </div>
                        <div class="col-3">
                            <h5>Store</h5>
                            <!-- <select class="custom-select form-select" required [(ngModel)]="storeId" >
                                <option value="0">--All--</option>
                                <option [value]="store.id" *ngFor="let store of stores">{{store.name}}</option>
                                
                            </select> -->
                          <div class="mt-2">
                            <ng-multiselect-dropdown #selectNg [placeholder]="'Choose Stores'" [data]="stores"
                                [(ngModel)]="selectedItems" [settings]="dropdownSettings"
                                (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                                (onDeSelect)="onDeSelect($event)">
                            </ng-multiselect-dropdown>
                        </div>
                        </div>

                        <div class="col-3">
                            <h5>Payment</h5>
                            <select class="custom-select form-select" required [(ngModel)]="paymentType" 
                            >
                                <option value="0">--All--</option>
                                <option value="1">Online Pay</option>
                                <option value="2">Cash</option>
                               
                                
                            </select>

                        </div>
                      

                        <div class="col-3 " style="float: right; margin-top: 30px">
                            <button class="btn btn-primary" (click)="Resetadv()">
                                Reset
                            </button>
                            &nbsp;
                            <button class="btn btn-success" (click)="GetCiReportIndex()" >
                                Fetch
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                
                    <div class="row align-items-center">
                 
                        <div class="col-md-4">
                            <div class="position-relative">
                                <input type="text" class="form-control" id="iconLeft2" placeholder="Search Store..."
                                    [(ngModel)]="SearchValues" (input)="searchTable()" autocomplete="off" />
                                <div class="form-control-position">
                                    <i class="ft-search"></i>
                                </div>
                            </div>
                        </div>
                    
                      
                        <div class="col-md-4 d-flex align-items-center">
                            <button class="btn btn-md btn-outline-info w-auto me-3" (click)="exportJson()">
                                Export to Excel <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                            </button>
                            <span class="text-danger" *ngIf="submit && filteredData.length == 0">No records found in the table</span>
                        </div>
                    
  
                        <div class="col-md-2 text-end">
                            <p class="fw-bold">Total Paid Amount: <span class="text-primary">{{ totalPaidAmount | currency:'INR':'symbol' }}</span></p>
                        </div>

                        <div class="col-md-2 text-end">
                            <button class="btn btn-warning" (click)="isCollapsed = !isCollapsed"
                                [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                                Advance Search
                            </button>
                        </div>
                    </div>
                    <br>
                    
                    
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th style=" background-color: #343a40;
                                    color: white;
                                    cursor: pointer;text-align: center;
                                  " >#</th>
                                    <th style="
                                  background-color: #343a40;
                                  color: white;
                                  cursor: pointer;text-align: center;
                                "  (click)="sortData('storename')">
                                        Store Name
                                      
                                    </th>

                                    <th style="
                                    background-color: #343a40;
                                    color: white;
                                    cursor: pointer;text-align: center;
                                  " >
                                          Payment Mode
                                       
                                      </th>

                                    <th style="
                                  background-color: #343a40;
                                  color: white;
                                  cursor: pointer;text-align: center;
                                " (click)="sortData('billAmt')">
                                        Bill Amount
                                     
                                    </th>

                                    <th style="
                                    background-color: #343a40;
                                    color: white;
                                    cursor: pointer;text-align: center;
                                  " (click)="sortData('paidAmt')">
                                         Paid Amount
                                       
                                      </th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                           
                                    <tr let *ngFor="let data of filteredData;let i = index">
                                        <th scope="row" style="align-content: center; text-align: center">
                                            {{ i + 1 }}
                                        </th>
                                        <td style="align-content: center; text-align: center">
                                            {{ data.sName}}
                                        </td>

                                        <td style="align-content: center; text-align: center">

                                            <span *ngIf="data.pti == 1" class="badge badge-info">Online Pay</span>
                                            <span *ngIf="data.pti == 2" class="badge badge-warning">Cash</span>
                
                            
                                          </td>

                                        <td style="align-content: center; text-align: center">
                                            {{ data.ba}}
                                        </td>
                                        <td style="align-content: center; text-align: center">
                                            {{ data.pa}}
                                        </td>
                            </tbody>
                        </table>

                       
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>