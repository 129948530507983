
<div class="container-fluid">
    <div class="page-header">
      <div class="row">
        <div class="col-lg-6">
          <div class="page-header-left">
            <h3>
             CUSTOMER - LIST
    
            </h3>
          </div>
        </div>
      
      </div>
    </div>
  </div>

<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">

        <div class="col-sm-12" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" id="cuslist_advancesearc">
            <div class="card">
                <div class="card-content">
                    <div class="card-body">
                        <div class="row">

                            <div class="col-3">
                                <h5><strong>From Date - To Date</strong> </h5>
                                <div class="form-group position-relative">
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Select Date"
                                            id="datePickerInput" (click)="openDatePicker()" [value]="
                                (fromDate ? formatDate(fromDate) : '') +
                                (fromDate ? ' / ' : '') +
                                (toDate ? formatDate(toDate) : '')
                              " autocomplete="off" />
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" type="button" (click)="openDatePicker()"
                                                *ngIf="isopen">
                                                <i class="fa fa-calendar" aria-hidden="true"></i>
                                            </button>
                                            <button class="btn btn-primary" type="button" (click)="clearval()"
                                                *ngIf="!isopen">
                                                <i class="fa fa-times" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngIf="datePickerOpened" class="datepicker-container-s border rounded">
                                        <ngb-datepicker #dp1 ngModel (ngModelChange)="onDateChange($event)"
                                            [displayMonths]="2" [dayTemplate]="t">
                                        </ngb-datepicker>

                                        <!-- Today, Yesterday, Last 7 days buttons -->
                                        <div class="text-center mt-2 mb-2">
                                            <button class="btn btn-primary mr-2" type="button" (click)="selectToday()"
                                                style="margin: 6px;">
                                                Today
                                            </button>
                                            <button class="btn btn-primary mr-2" type="button"
                                                (click)="selectYesterday()" style="margin: 6px;">
                                                Yesterday
                                            </button>
                                            <button class="btn btn-primary" type="button" (click)="selectLast7Days()"
                                                style="margin: 6px;">
                                                Last 7 days
                                            </button>
                                        </div>


                                        <ng-template #t let-date="date" let-focused="focused">
                                            <span class="custom-day" [class.focused]="focused" [class.range]="
        isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
                                                [class.faded]="isHovered(date) || isInside(date)"
                                                (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null"
                                                (dblclick)="setFromDateToDate(date)">
                                                {{ date.day }}
                                            </span>
                                        </ng-template>
                                    </div>
                                </div>

                            </div>
                             <div class="col-3">

                                    <h5>Customer Name</h5>
                                    <input type="text" class="form-control" [(ngModel)]="cname">
                                </div>
                            <div class="col-3">

                                <h5>Customer PhoneNo</h5>
                                <input type="number" class="form-control" [(ngModel)]="cphone">
                            </div>



                            <div class="col-3 ">
                                <h5><strong>Status</strong> </h5>
                                <select class="custom-select form-select" [(ngModel)]="records"
                                    (change)="selectrecors($event.target.value)" required>

                                    <option value="0">All</option>
                                    <option value="100">Top 100</option>
                                    <option value="500">Top 500</option>
                                    <option value="1000">Top 1000</option>

                                </select>
                            </div>

                            <div class="col-3" style="    place-content: center;">
                                <h5></h5>
                                <button class="btn btn-primary mt-4" (click)="Resetadv()">
                                    Reset
                                </button>
                                &nbsp;
                                <button class="btn btn-success mt-4" (click)="getcustomer()">
                                    Fetch
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12" id="cuslist_index">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-4">
                            <input type="text" class="form-control" id="iconLeft2"
                                placeholder="Search Customer Name (Or) Phone-No..." [(ngModel)]="SearchValues"
                                (input)="searchTable()" autocomplete="off" />
                            <div class="form-control-position">
                                <i class="ft-search"></i>
                            </div>
                        </div>
                        <div class="form-group row col-5">

                        </div>
                        <div class="col-3" style="text-align: right">
                            <button class="btn btn-warning" (click)="isCollapsed = !isCollapsed"
                                [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                                Advance Search
                            </button>
                        </div>
                    </div> <br>
                    <div class="table-responsive" id="cuslist_table">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th style="background-color: #343a40; color: white">#</th>
                                    <th style="background-color: #343a40;color: white;cursor: pointer;"
                                        (click)="sortData('Id')">
                                        Customer-Id
                                        <span *ngIf="sortField === 'Id'">
                                            <i class="fa" [ngClass]="sortOrder ? 'fa-sort-up' : 'fa-sort-down'"
                                                style="font-size: 20px" aria-hidden="true"></i>
                                        </span>
                                    </th>

                                    <th style="background-color: #343a40;color: white;cursor: pointer;"
                                        (click)="sortData('Name')">
                                        Customer-Name
                                        <span *ngIf="sortField === 'Name'">
                                            <i class="fa" [ngClass]="sortOrder ? 'fa-sort-up' : 'fa-sort-down'"
                                                style="font-size: 20px" aria-hidden="true"></i>
                                        </span>
                                    </th>

                                    <th style="background-color: #343a40;color: white;cursor: pointer;"
                                        (click)="sortData('PhoneNo')">
                                        Phone-No
                                        <span *ngIf="sortField === 'PhoneNo'">
                                            <i class="fa" [ngClass]="sortOrder ? 'fa-sort-up' : 'fa-sort-down'"
                                                style="font-size: 20px" aria-hidden="true"></i>
                                        </span>
                                    </th>

                                    <th style="background-color: #343a40;color: white;cursor: pointer;"
                                        (click)="sortData('CreatedDate')">
                                        Created Date
                                        <span *ngIf="sortField === 'CreatedDate'">
                                            <i class="fa" [ngClass]="sortOrder ? 'fa-sort-up' : 'fa-sort-down'"
                                                style="font-size: 20px" aria-hidden="true"></i>
                                        </span>
                                    </th>
                                    <th style="background-color: #343a40;color: white;cursor: pointer;"
                                        (click)="sortData('isBlocked')">
                                        Is-Blocked
                                        <span *ngIf="sortField === 'isBlocked'">
                                            <i class="fa" [ngClass]="sortOrder ? 'fa-sort-up' : 'fa-sort-down'"
                                                style="font-size: 20px" aria-hidden="true"></i>
                                        </span>
                                    </th>

                                    <th style=" background-color: #343a40; color: white; cursor: pointer; "
                                        (click)="sortData('OrderCount')">
                                        OrderCount
                                        <span *ngIf="sortField === 'OrderCount'">
                                            <i class="fa" [ngClass]="sortOrder ? 'fa-sort-up' : 'fa-sort-down'"
                                                style="font-size: 20px" aria-hidden="true"></i>
                                        </span>
                                    </th>

                                    <th style="background-color: #343a40; color: white">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let cust of EcomCustomerData;let i = index">
                                    <th scope="row">{{ cust.Sno}}</th>
                                    <td>
                                        {{cust.id}}
                                    </td>
                                    <td>
                                        {{cust.name}}
                                    </td>
                                    <td>{{cust.phoneNo}}</td>

                                    <td>
                                        {{ cust.createdDate | date:'MMMM d, y h:mm a' }}

                                    </td>
                                    <td>
                                        <span *ngIf="cust.isBlocked == true" class="badge badge-primary"
                                            style="font-size: smaller;">
                                            Blocked</span>

                                        <span *ngIf="cust.isBlocked == false" style="font-size: smaller;">
                                            -</span>

                                    </td>
                                    <td>
                                        <!-- <span *ngIf="cust.status > 1"> Old Customer</span>  -->
                                        <span *ngIf="cust.orderCount == 0" class="badge badge-success"
                                            style="font-size: smaller;">

                                            New Customer</span>
                                        <span *ngIf="cust.orderCount > 0" class="badge badge-warning"
                                            style="font-size: smaller;">
                                            {{cust.orderCount}} Orders</span>

                                    </td>
                                    <td>
                                        <div class="d-flex justify-content-around">
                                            <div class="col-4 col-sm-4 col-md-4">
                                                <a href="javascript:void(0)"
                                                    (click)="GetCustAddrs(content, cust.id,cust.name)">
                                                    <i class="fa fa-info-circle" aria-hidden="true"
                                                        style="font-size: 20px; color: #13c9ca;"
                                                        ngbTooltip="Details"></i>
                                                </a>
                                            </div>

                                            <div class="col-4 col-sm-4 col-md-4">
                                                <a href="https://api.whatsapp.com/send/?phone=916381629875&text=%2AHi%2C+I+am+Basky%21%2A%0A%0APlz+complete+your+order+now+and+%2AOffers+Moving..%2A%0A%0A%2AOffers%2A+%0A-%3E+%28Blast+Cakes%29+Buy+1+kg+Get+1+kg+Free%21+%0A-%3E+%28Cakes%29+Buy+1+kg+Get+1%2F2+kg+Free%21%0A-%3E+Delivery+Charge+Free%21%2A%0A%0AValid+for+24+hrs.%0A%0AThanks+%0ATeam+FBcakes%0A_https%3A%2F%2Ffbcakes.com_&type=phone_number&app_absent=0"
                                                    target="_blank">
                                                    <i class="fa fa-whatsapp" aria-hidden="true"
                                                        style="font-size: 20px; color: green;"
                                                        ngbTooltip="Whatsapp Msg"></i>
                                                </a>
                                            </div>

                                            <div class="col-4 col-sm-4 col-md-4">
                                                <a href="javascript:void(0)" (click)="BlockCustomer(blockcust,cust)">
                                                    <i class="fa fa-ban" aria-hidden="true"
                                                        style="font-size: 20px; color: red;" ngbTooltip="Block"></i>
                                                </a>
                                            </div>

                                        </div>

                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="EcomCustomerData.length == 0">
                                <tr>
                                    <td colspan="100%" class="no-data-container">
                                        <i class="fa fa-database" aria-hidden="true"></i>
                                        <p>No data available</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <div class="d-flex justify-content-center p-2">
                            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                                (pageChange)="refreshpagination()">
                            </ngb-pagination>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>



<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header" style="padding: 5px">
        <div class="row w-100">
            <div class="col">
                <h4 class="modal-title" style="margin-top: 2%;margin-left: 15px;">Customer Address:- <span
                        style="color:red">{{CustName}}</span></h4>
            </div>
            <div class="col" style="text-align: end">
                <button id="closebtnhaa" style="border-radius: 100px; color: white; padding: 1px" type="button"
                    aria-label="Close" class="close btn btn-primary btn-sm m-1" (click)="d('Cross click');">
                    <i class="fa fa-times" aria-hidden="true" style="
                color: #ffff;
                font-size: large;
                margin-top: 4px;
                margin-bottom: 4px;
                margin-left: 6px;
                margin-right: 6px;
              "></i>
                </button>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <div class="row" *ngIf="Address.length > 0">
            <div class="col-12 col-md-6 col-lg-4 mb-3" *ngFor="let addrs of Address; let i = index">
                <div class="card h-100">
                    <div class="card-header" style="    padding: 10px;">
                        <strong style="font-size: larger;">Address {{ i + 1 }}</strong>
                    </div>
                    <div class="card-body" style="padding: 10px;">
                        <div class="d-flex justify-content-between mb-2">
                            <label><strong>Address:</strong></label>
                            <span class="text-lg-end">{{ addrs.address }}</span>
                        </div>
                        <div class="d-flex justify-content-between mb-2">
                            <label><strong>City/PinCode:</strong></label>
                            <span>{{ addrs.city }} / {{ addrs.pincode }}</span>
                        </div>
                        <div class="d-flex justify-content-between mb-2">
                            <label><strong>Phone-No:</strong></label>
                            <span>{{ addrs.contact }}</span>
                        </div>
                        <div class="d-flex justify-content-between mb-2">
                            <label><strong>LandMark:</strong></label>
                            <span>{{ addrs.landMark }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="Address.length == 0">
        
            <div class="col-lg-12 text-center" style="    padding: 20px;">
                <i style="    font-size: 62px;    padding-bottom: 30px;color: red;" class="fa fa-info-circle" aria-hidden="true"></i>

                <h4>No customer Address!</h4>

            </div>

        </div>
    </div>
</ng-template>




<ng-template #blockcust let-c="close" let-d="dismiss">
    <div class="modal-header" style="padding: 5px">
        <div class="row w-100">
            <div class="col -lg-8">
                <h4 class="modal-title" style="margin-top: 2%;margin-left: 15px;">Block Customer:- <span
                        style="color:red">{{CustName}}</span></h4>
            </div>
            <div class="col-lg-4" style="text-align: end">
                <button id="closebtnhaa" style="border-radius: 100px; color: white; padding: 1px" type="button"
                    aria-label="Close" class="close btn btn-primary btn-sm m-1" (click)="d('Cross click');">
                    <i class="fa fa-times" aria-hidden="true" style="
                color: #ffff;
                font-size: large;
                margin-top: 4px;
                margin-bottom: 4px;
                margin-left: 6px;
                margin-right: 6px;
              "></i>
                </button>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <div class="icon-box text-lg-center">
            <span style="color: red;    font-size: 35px;"><i class="fa fa-ban" aria-hidden="true"
                    style="    margin-top: 20px;"></i></span>
        </div>
        <h4 class="modal-title w-100" style="    text-align: center;
        margin-top: 20px;">Are you sure?</h4>

        <p style="text-align: center;
        margin-top: 10px;">Do you really want to Block these Customer?</p>

    </div>
    <div class="modal-footer">
        <button type="button" *ngIf="isBlocked == false" class="btn btn-primary-gradien btn-sm"
            (click)="saveBlockCustomer(true)">Block</button>

        <button type="button" *ngIf="isBlocked == true" class="btn btn-info-gradien btn-sm"
            (click)="saveBlockCustomer(false)">UnBlock</button>

    </div>

</ng-template>


<div style="display: none;    z-index: 999;
    position: fixed;" id="preloadercuslist">
    <div class="bouncing-loader">
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>