import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unauthroized',
  templateUrl: './unauthroized.component.html',
  styleUrls: ['./unauthroized.component.scss']
})
export class UnauthroizedComponent {
  constructor(private router: Router) { }

  Lock() {
    this.router.navigateByUrl('/auth/pin')
    sessionStorage.removeItem('user')
    sessionStorage.removeItem('userSettindata')
  }

}
