import { Component } from '@angular/core';
import { NgbDateStruct,NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-companywise-report',
  templateUrl: './companywise-report.component.html',
  styleUrls: ['./companywise-report.component.scss']
})
export class CompanywiseReportComponent {
 constructor(private auth: AuthService)
    {
      
    }

  ngOnInit()
  {
    // this. GetTransReportIndex()
    this.GetAllStores()
    this.getAllCompany()
    this.GetCiReportIndex()

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Clear All',
      itemsShowLimit: 3,

      allowSearchFilter: true
    };

  }
  compDataIndex:any = []
  sortOrder = true;
  sortField = "";
  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  fromDateStr: string = "";
  toDateStr: string = "";
  isCollapsed = true;
  orderId: number = 0
  storeId:number = 0
  searchTerm: string ='';
  formatter = (result: any) => result.strname;
  datePickerOpened: boolean = false;
  isopen = true
  paymentType :number =1

  filteredData:any = []
  submit: boolean = false
  stores:any = []
  companyId:number = 0
  totalPaidAmount:number = 0
  // storeIds: number[]
    GetCiReportIndex()
    {
      // this. GetAllStores()
    this.auth.getCompanyReport(this.companyId,this.filteredStores,this.fromDateStr,this.toDateStr,this.paymentType).subscribe((x:any)=>
    {
       console.log(x)
      this.compDataIndex = x['companyData']
      console.log(this.compDataIndex)
      this.filteredData = this.compDataIndex 
console.log(this.stores)

console.log(this.filteredData)

this.totalPaidAmount = this.filteredData.reduce((sum, item) => sum + item.pa, 0);
console.log("Total Paid Amount:", this.totalPaidAmount);


    })
    }
  


    Resetadv() {
   this.orderId =0
   this.fromDate = null
   this.toDate = null
    this.fromDateStr = '',  
    this.toDateStr = ''
    this.storeId = 0
    this.paymentType = 1
    this.companyId = 0
    this.filteredStores = null
    this.selectedItems = null
    this.GetCiReportIndex()
    
    }
  
    companies:any = []
  
    GetAllStores() {
      this.auth.GetAllStores().subscribe((data: any) => {
        console.log(data)
        this.stores = data
       
     
      });
    }
    getAllCompany()
    {
      this.auth.getallCompany().subscribe((data: any) => {
        console.log(data)
        this.companies = data
        
     
      });
    }
    filteredStores: any = []
    onItemSelect(item: any) {
      this.updateFilteredProduct();
    }

    onSelectAll(items: any) {
   this.filteredStores = null
   console.log(this.filteredStores)
    }

    onDeSelect(items: any) {
      this.updateFilteredProduct();
      
    }

    updateFilteredProduct() {
      this.filteredStores = this.selectedItems.map((item: any) => item.id);
      console.log(this.filteredStores);
    }
    





      //filter table
  SearchValues: string = "";
  filteredosi: any;
  storelist:any
  dropdownSettings = {};
  selectedItems = [];
  searchTable() {
    const searchValue = this.SearchValues.toLowerCase();
    const dataToSearch = this.filteredData || this.compDataIndex;

    if (searchValue) {
      // Only filter if there's a search value
      this.filteredData = dataToSearch.filter((order: any) => {
        const store = order.sName && order.sName.toLowerCase().includes(searchValue);
        
        return store  
      });
    } else {

      this.filteredData = this.compDataIndex;
    }

    console.log(this.filteredData);
  }



  openDatePicker() {

    this.isopen = false
    this.datePickerOpened = true;
    console.log("Opening date picker...");
  }

  formatDate(date: NgbDateStruct) {
      return `${date.year}-${date.month}-${date.day}`;
    }

    
  clearval() {
    this.fromDate = null
    this.toDate = null
    this.datePickerOpened = false
    this.isopen = true
    this.fromDateStr = ''
    this.toDateStr = ''

  }


    onDateChange(date: NgbDateStruct | null) {
      console.log(date)
      if (date) {
        if (!this.fromDate) {
          this.fromDate = date;
        } else if (!this.toDate && after(date, this.fromDate)) {
          this.toDate = date;
          this.datePickerOpened = false; // Close the date picker after selecting the range
          this.logSelectedDateRange(); // Log the selected date range
        } else {
          this.toDate = null;
          this.fromDate = null;
  
        }
      }
    }

    logSelectedDateRange() {
      if (this.fromDate && this.toDate) {
        console.log(
          "Selected Date Range:",
          this.formatDate(this.fromDate),
          "to",
          this.formatDate(this.toDate)
        );
        const fromDateStr = this.formatDate(this.fromDate);
        const toDateStr = this.formatDate(this.toDate);
        console.log(fromDateStr, toDateStr)
        this.fromDateStr = fromDateStr
        this.toDateStr = toDateStr
        // this.dateRangeSelected.emit({ fromDateStr, toDateStr });
      }
    }
  

    selectToday() {
      const today = new Date();
      this.fromDate = {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        day: today.getDate(),
      };
      this.toDate = {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        day: today.getDate(),
      };
      this.datePickerOpened = false;
      this.logSelectedDateRange();
    }
  
    selectYesterday() {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      this.fromDate = {
        year: yesterday.getFullYear(),
        month: yesterday.getMonth() + 1,
        day: yesterday.getDate(),
      };
      this.toDate = {
        year: yesterday.getFullYear(),
        month: yesterday.getMonth() + 1,
        day: yesterday.getDate(),
      };
      this.datePickerOpened = false;
      this.logSelectedDateRange();
    }
  
    selectLast7Days() {
      const today = new Date();
      const last7Days = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
      this.fromDate = {
        year: last7Days.getFullYear(),
        month: last7Days.getMonth() + 1,
        day: last7Days.getDate(),
      };
      this.toDate = {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        day: today.getDate(),
      };
      this.datePickerOpened = false;
      this.logSelectedDateRange();
    }
  

    isHovered = (date) =>
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      after(date, this.fromDate) &&
      before(date, this.hoveredDate);
  
    isInside = (date) => after(date, this.fromDate) && before(date, this.toDate);
  
    isFrom = (date) => equals(date, this.fromDate);
  
    isTo = (date) => equals(date, this.toDate);


    setFromDateToDate(date: NgbDateStruct) {
      this.fromDate = date;
      this.toDate = date;
      this.datePickerOpened = false;
      this.logSelectedDateRange();
    }
  
    ExcelData: any = []

    exportJson(): void {
      this.submit = true
      this.ExcelData = []


      this.ExcelData = this.filteredData.map(item => ({
  
        storeName: item.sName,
        BillAmount :item.ba,
        PaidAmount :item.pa
    
      }));

      console.log(this.ExcelData);
      this.auth.exportAsExcelFile(this.ExcelData, 'OrderReport');
    }
   
    sortData(field: string) {
      if (this.sortField === field) {
        this.sortOrder = !this.sortOrder;
      } else {
        this.sortOrder = true;
      }
      this.sortField = field;
  
      this.filteredData.sort((a, b) => {
        let valueA, valueB;
  
        if (field === "storename") {
          valueA = a.sName;
          valueB = b.sName;
        } else if (field === "payment") {
          valueA = a.pti;
          valueB = b.pti;
        } else if (field === "billAmt") {
          valueA = a.ba;
          valueB = b.ba;
        } else if (field === "paidAmt") {
          valueA = a.pa;
          valueB = b.pa;
        } 
  
        if (typeof valueA === "string") {
          valueA = valueA.toLowerCase();
          valueB = valueB.toLowerCase();
        }
  
        if (valueA < valueB) {
          return this.sortOrder ? -1 : 1;
        } else if (valueA > valueB) {
          return this.sortOrder ? 1 : -1;
        } else {
          return 0;
        }
      });
    }

    }


// DateRange-Picker

const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one &&
  two &&
  two.year === one.year &&
  two.month === one.month &&
  two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
      ? one.month === two.month
        ? one.day === two.day
          ? false
          : one.day < two.day
        : one.month < two.month
      : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
      ? one.month === two.month
        ? one.day === two.day
          ? false
          : one.day > two.day
        : one.month > two.month
      : one.year > two.year;