import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from "@angular/core";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one &&
  two &&
  two.year === one.year &&
  two.month === one.month &&
  two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day < two.day
      : one.month < two.month
    : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
    ? one.month === two.month
      ? one.day === two.day
        ? false
        : one.day > two.day
      : one.month > two.month
    : one.year > two.year;
@Component({
  selector: "app-customrangepicker",
  templateUrl: "./customrangepicker.component.html",
  styleUrls: ["./customrangepicker.component.scss"],
})
export class CustomrangepickerComponent implements OnInit {
  @Output() dateRangeSelected = new EventEmitter<any>();

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {}

  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;

  @HostListener("document:click", ["$event"])
  handleOutsideClick(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.datePickerOpened = false;
    }
  }

  isHovered = (date) =>
    this.fromDate &&
    !this.toDate &&
    this.hoveredDate &&
    after(date, this.fromDate) &&
    before(date, this.hoveredDate);

  isInside = (date) => after(date, this.fromDate) && before(date, this.toDate);

  isFrom = (date) => equals(date, this.fromDate);

  isTo = (date) => equals(date, this.toDate);

  datePickerOpened: boolean = false;

  isopen=true

  openDatePicker() {

    this.isopen=false
    this.datePickerOpened = true;
    console.log("Opening date picker...");
  }

  closeDatePicker() {
    this.datePickerOpened = false;
    this.logSelectedDateRange();
  }

  selectToday() {
    const today = new Date();
    this.fromDate = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate(),
    };
    this.toDate = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate(),
    };
    this.datePickerOpened = false;
    this.logSelectedDateRange();
  }

  selectYesterday() {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    this.fromDate = {
      year: yesterday.getFullYear(),
      month: yesterday.getMonth() + 1,
      day: yesterday.getDate(),
    };
    this.toDate = {
      year: yesterday.getFullYear(),
      month: yesterday.getMonth() + 1,
      day: yesterday.getDate(),
    };
    this.datePickerOpened = false;
    this.logSelectedDateRange();
  }

  selectLast7Days() {
    const today = new Date();
    const last7Days = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    this.fromDate = {
      year: last7Days.getFullYear(),
      month: last7Days.getMonth() + 1,
      day: last7Days.getDate(),
    };
    this.toDate = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate(),
    };
    this.datePickerOpened = false;
    this.logSelectedDateRange();
  }

  onDateChange(date: NgbDateStruct | null) {
    if (date) {
      if (!this.fromDate) {
        this.fromDate = date;
      } else if (!this.toDate && after(date, this.fromDate)) {
        this.toDate = date;
        this.datePickerOpened = false; // Close the date picker after selecting the range
        this.logSelectedDateRange(); // Log the selected date range
      } else {
        this.toDate = null;
        this.fromDate = null;
        
      }
    }
  }

  setFromDateToDate(date: NgbDateStruct) {
    this.fromDate = date;
    this.toDate = date;
    this.datePickerOpened = false;
    this.logSelectedDateRange();
  }

  fromDateStr: string = "";
  toDateStr: string = "";
  logSelectedDateRange() {
    if (this.fromDate && this.toDate) {
      console.log(
        "Selected Date Range:",
        this.formatDate(this.fromDate),
        "to",
        this.formatDate(this.toDate)
      );
      const fromDateStr = this.formatDate(this.fromDate);
      const toDateStr = this.formatDate(this.toDate);
      this.dateRangeSelected.emit({ fromDateStr, toDateStr });
    }
  }

  formatDate(date: NgbDateStruct) {
    return `${date.year}-${date.month}-${date.day}`;
  }


  clearval(){
    this.fromDate=null
    this.toDate=null
    this.datePickerOpened=false
    this.isopen=true
  }

  // Range datepicker ends
}
