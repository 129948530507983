<div class="page-wrapper">
    <div class="imagebox">
        <div class="authentication-box" style="position: absolute;">
            <div class="container">
                <div class="row" style="justify-content: center;">
                    <div class="col-md-7 card-right">
                        <div class="card tab2-card">
                            <div class="card-body">
                                <div class="text-center">
                                    <h2 class="form-title text-capitalize">Create Your Account</h2>
                                </div>
                                <form [formGroup]="registerForm" (ngSubmit)="onSubmit()"
                                    class="form-horizontal auth-form mt-4" novalidate>
                                    <div class="form-group">
                                        <label for="FullName" class="f-18">Full Name:</label>
                                        <input id="FullName" formControlName="FullName" type="text" class="form-control"
                                            style="padding: 10px;" autocomplete="off" aria-label="Full Name">
                                        <small
                                            *ngIf="registerForm.get('FullName')?.touched && registerForm.get('FullName')?.invalid"
                                            class="text-danger">Full Name is required</small>
                                    </div>
                                    <div class="form-group">
                                        <label for="phoneNo" class="f-18">Phone No:</label>
                                        <input id="phoneNo" formControlName="PhoneNo" type="tel" class="form-control"
                                            style="padding: 10px;" autocomplete="off" aria-label="Phone No">
                                        <small
                                            *ngIf="registerForm.get('PhoneNo')?.touched && registerForm.get('PhoneNo')?.invalid"
                                            class="text-danger">Valid Phone Number is required</small>
                                    </div>
                                    <div class="form-group">
                                        <label for="email" class="f-18">Email:</label>
                                        <input id="email" formControlName="Email" type="email" class="form-control"
                                            style="padding: 10px;" autocomplete="off" aria-label="Email">
                                        <small
                                            *ngIf="registerForm.get('Email')?.touched && registerForm.get('Email')?.invalid"
                                            class="text-danger">Valid Email is required</small>
                                    </div>
                                    <div class="form-group position-relative">
                                        <label for="password" class="f-18">Password:</label>
                                        <input id="password" formControlName="Password" style="padding: 10px;"
                                            [type]="isPasswordVisible ? 'text' : 'password'" class="form-control"
                                            aria-label="Password">
                                        <i [class]="isPasswordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'"
                                            class="toggle-password-icon" (click)="togglePasswordVisibility()"
                                            aria-hidden="true"
                                            style="position: absolute; top: 50%; right: 10px; transform: translateY(-50%); cursor: pointer;"></i>
                                        <small
                                            *ngIf="registerForm.get('Password')?.touched && registerForm.get('Password')?.invalid"
                                            class="text-danger">Password must be at least 6 characters long</small>
                                    </div>
                                    <div class="form-group position-relative">
                                        <label for="confirmPassword" class="f-18">Confirm Password:</label>
                                        <input id="confirmPassword" formControlName="ConfirmPassword"
                                            style="padding: 10px;" [type]="isconfirmVisible ? 'text' : 'password'"
                                            class="form-control" aria-label="Confirm Password">
                                        <i [class]="isconfirmVisible ? 'fa fa-eye-slash' : 'fa fa-eye'"
                                            class="toggle-password-icon" (click)="toggleconfirmVisibility()"
                                            aria-hidden="true"
                                            style="position: absolute; top: 50%; right: 10px; transform: translateY(-50%); cursor: pointer;"></i>
                                        <small
                                            *ngIf="registerForm.get('ConfirmPassword')?.touched && registerForm.get('ConfirmPassword')?.invalid"
                                            class="text-danger">Passwords must match</small>
                                    </div>
                                    <div class="form-button text-center">
                                        <button class="btn btn-primary" type="submit" [disabled]="registerForm.invalid">
                                            Sign Up
                                        </button>
                                    </div>
                                    <hr>
                                    <div class="text-center">
                                        <span>Already have an account? <a [routerLink]="'/login'">Login</a></span>
                                    </div>
                                </form>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>