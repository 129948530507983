import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import * as chartData from '../../shared/data/chart';
import { ReportDB, REPORTDB } from 'src/app/shared/tables/report';
import { distinctUntilChanged, map, Observable, OperatorFunction } from 'rxjs';
import { TableService } from 'src/app/shared/service/table.service';
import { NgbdSortableHeader, SortEvent } from 'src/app/shared/directives/NgbdSortableHeader';
import { DecimalPipe } from '@angular/common';
import { AuthService } from '../auth.service';
import * as moment from 'moment';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SignalRService } from '../pages/ecom-dash-board/signalr.service';
//import { id } from '@swimlane/ngx-charts';
import html2canvas from "html2canvas";
import { sum } from 'chartist';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  providers: [TableService, DecimalPipe]
})
export class ReportsComponent implements OnInit {

  // lineChart
  public salesChartData = chartData.salesChartData;
  public salesChartLabels = chartData.salesChartLabels;
  public salesChartOptions = chartData.salesChartOptions;
  public salesChartColors = chartData.salesChartColors;
  public salesChartLegend = chartData.salesChartLegend;
  public salesChartType = chartData.salesChartType;

  public areaChart1 = chartData.areaChart1;
  public columnChart1 = chartData.columnChart1;
  public lineChart = chartData.lineChart;

  public chart5 = chartData.chart6

  public tableItem$: Observable<ReportDB[]>;
  public searchText;
  total$: Observable<number>;

  constructor(public service: TableService, private auth: AuthService, private modalService: NgbModal, private signalRService: SignalRService) {
    this.tableItem$ = service.tableItem$;
    this.total$ = service.total$;
    this.service.setUserData(REPORTDB)
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;

  }

  trans = false;
  isCollapsed = true;
  ngOnInit() {
   // this.getdata()
    this.LoadInitialdata();
   // this.getOrdReport()
  }

  //load initial values
  LoadInitialdata() {
    this.GetAllStores();
    this.signalRService.connect();
    // this.signalRService.addOrderListener((storeId: number) => {
    //   this.handleNewOrder(storeId);
    // });
    // document.getElementById("spin").style.display = "none";
    // document.getElementById("preload").style.display = "block";
    // const frmdate = "2024-08-01";
    // const todate = new Date();
    // this.fromDate = moment(frmdate).format("YYYY-MM-DD");
    // this.toDate = moment(todate).format("YYYY-MM-DD");


  }

  //signalR store
  handleNewOrder(storeId: number) {
    // console.log("HyperTest: ", storeId);
  }
  // Table Index
  GetReceiptValues = [];
  savedfilename: string;
  colorchageinvoice: string;
  fromDate: any = '';
  toDate: any = '';
  storeid = 0;
  GetOrdersValues = [];
  stores = [];
  updatedval: any = [];
  filteredOrders: any[] = [];
  selectedStatus: number = 7;

  collectionSize: any
  page = 1;
  pageSize = 10;


  // onChange(event: { fromDateStr: string; toDateStr: string }) {
  //   this.fromDate = event.fromDateStr;
  //   this.toDate = event.toDateStr;
  // }


  selectedDate: any = null; // Pre-selected date
  formattedDate: string = '';

  onDateChange(date: any): void {
    // Convert the NgbDatepicker object to 'YYYY-MM-DD' format
    this.formattedDate = `${date.year}-${String(date.month).padStart(2, '0')}-${String(date.day).padStart(2, '0')}`;
    this.orderedDate = this.formattedDate
    // console.log('Formatted Date:', this.formattedDate);
  }

  selectedDatedelivery: any = null; // Pre-selected date
  formattedDatedelivery: string = '';

  onDateChangedelivery(date: any): void {
    // Convert the NgbDatepicker object to 'YYYY-MM-DD' format
    this.formattedDatedelivery = `${date.year}-${String(date.month).padStart(2, '0')}-${String(date.day).padStart(2, '0')}`;
    this.deliveredDate = this.formattedDatedelivery
    // console.log('Formatted Date:', this.formattedDatedelivery);
  }
  // GetOrderReports

  getOrdReport() {
    const payload = {
      orderid: this.orderId || 0,
      ordereddate: this.orderedDate || null,
      deliverydate: this.deliveredDate || null,
      statusid: this.statusid,
      msg: this.birthdayMsg || null,
      deliverystoreid: this.storeid || 0
    }
    document.getElementById('reportload').style.display = 'block'
    this.auth.GetOrderReports(payload).subscribe((data: any) => {

      document.getElementById('reportload').style.display = 'none'

      // console.log(data)
      if (data["status"] !== 200) return;


      this.GetOrdersValues = data["data"]
      console.log(this.GetOrdersValues)
      // console.log(this.stores)

      this.GetOrdersValues = this.GetOrdersValues.filter(
        (val: any) => val.deliverystore !== 10 && val.deliverystore !== 7
      );
      this.GetOrdersValues.forEach((val: any) => {
        val.storeName = this.stores.find(
          store => store.id === val.deliverystore 
        )?.strname || "Unknown Store";

      });
      this.collectionSize = this.GetOrdersValues.length

      this.filteredOrders = this.GetOrdersValues;
      this.filteredOrders.forEach((element, i) => {
        element.Sno = i + 1
      });

      // console.log(this.filteredOrders)
      this.refreshpagination();
    })
    // this.auth.GetOrderReports(payload);
  }


  refreshpagination() {
    this.updatedval = this.filteredOrders.map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    // console.log(this.updatedval);
  }

  //modal order details
  notesInd: string = ''
  CancelInd: string = ''
  OrderDetails: any;
  Note: any;
  CustomerDetails: any = []
  ProductDetails: any = []
  OrdDetails: any = []
  OpenOrderdetails(content, data) {
   console.log(data)
    document.getElementById('reportload').style.display = 'block'

    this.auth.GettOrdDetails(data.orderid).subscribe(data => {
      document.getElementById('reportload').style.display = 'none'
      // console.log(data)
      this.OrdDetails = data["orderDetails"]
      this.ProductDetails = data["productDetails"]
      this.CustomerDetails = data["customerDetails"]

      this.OrdDetails.forEach((val: any) => {
        val.storeName = this.stores.find(
          store => store.id === val.deliverystoreid
        )?.strname || "Unknown Store";

      });
      this.Note = JSON.parse(this.OrdDetails[0].n);

      console.log(this.Note)

      // console.log(this.OrdDetails)
      // console.log(this.ProductDetails)
      // console.log(this.CustomerDetails)

      this.modalService.open(content, {
        centered: true,
        size: "xl",
      });

      // this.Note = this.OrderDetails.n;
    })


    // if (data.CustomerData == null) {
    //   console.log('no data')
    // } else {
    //   this.OrderDetails = data;
    //   this.OrderDetails.j.forEach((ord) => {
    //     ord.product.v_name = ord.product.v_name?.replace(/\s*\(.*?\)\s*/g, "");
    //   });
    //   this.Note = this.OrderDetails.n;
    //   this.modalService.open(content, {
    //     centered: true,
    //     size: "xl",
    //   });
    // }
  }


  //header sort
  sortOrder = true;
  sortField = "";

  sortData(field: string) {
    if (this.sortField === field) {
      this.sortOrder = !this.sortOrder;
    } else {
      this.sortOrder = true;
    }
    this.sortField = field;

    this.updatedval.sort((a, b) => {
      let valueA, valueB;

      if (field === "InvoiceNo") {
        valueA = a.invoice;
        valueB = b.invoice;
      } else if (field === "Customer") {
        valueA = a.customername || "";
        valueB = b.customername || "";
      } else if (field === "OrderedDate") {
        valueA = new Date(a.orderdatetime);
        valueB = new Date(b.orderdatetime);
      } else if (field === "DeliveryDate") {
        valueA = new Date(a.deliverydatetime);
        valueB = new Date(b.deliverydatetime);
      } else if (field === "Store") {
        valueA = a.storeName || "";
        valueB = b.storeName || "";
      } else if (field === "Status") {
        valueA = a.statusid;
        valueB = b.statusid;
      }

      if (typeof valueA === "string") {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
      }

      if (valueA < valueB) {
        return this.sortOrder ? -1 : 1;
      } else if (valueA > valueB) {
        return this.sortOrder ? 1 : -1;
      } else {
        return 0;
      }
    });
  }

  //img modal
  img: any;
  openImgModal(customModal, data): void {
    this.img = data;
    document.getElementById("content-to-blur").style.display = "none";
    document.getElementById("content-to-blur1").style.display = "none";
    this.modalService
      .open(customModal, {
        centered: true,
        size: "lg",
      })
      .result.then(
        () => this.removeBlur(),
        () => this.removeBlur()
      );
  }
  removeBlur() {
    document.getElementById("content-to-blur").style.display = "block";
    document.getElementById("content-to-blur1").style.display = "block";
  }

  //modal close
  onDismiss(reason: string) {
    this.resetOrderStatusId();
    this.modalService.dismissAll(reason);
  }

  //resetorder status
  orderstatusid: number = 0;
  SelectedStore = [];
  resetOrderStatusId() {
    this.orderstatusid = null;
    this.SelectedStore = null;
  }

  //modal text
  getStatusText(status: number): string {
    switch (status) {
      case 5:
        return "Completed";
      case 0:
        return "Pending";
      case 1:
        return "Accepted";
    }
  }


  //filter table
  SearchValues: string = "";
  filteredosi: any;

  // searchTable() {
  //   const searchValue = this.SearchValues.toLowerCase();
  //   const dataToSearch =
  //     this.filteredosi != null ? this.filteredosi : this.filteredOrders;
  //   this.updatedval = dataToSearch.filter((order: any) => {
  //     const inoMatches =
  //       order.invoice && order.invoice.toLowerCase().includes(searchValue);
  //     const customerNameMatches =
  //       order.customername &&
  //       order.customername.toLowerCase().includes(searchValue);
  //     const customerPhoneMatches =
  //       order.customerphone &&
  //       order.customerphone.toLowerCase().includes(searchValue);
  //     const Store =
  //       order.storeName && order.storeName.toLowerCase().includes(searchValue);

  //     return inoMatches || customerNameMatches || customerPhoneMatches || Store;
  //   });

  //   // this.updateOrderCounts();
  // }



  searchTable() {
    const searchValue = this.SearchValues.toLowerCase();
    console.log(searchValue)
    this.filteredOrders = this.GetOrdersValues.filter((order: any) => {
      const inoMatches =
        order.invoice && order.invoice.toLowerCase().includes(searchValue);
      const customerNameMatches =
        order.customername &&
        order.customername.toLowerCase().includes(searchValue);
      const customerPhoneMatches =
        order.customerphone &&
        order.customerphone.toLowerCase().includes(searchValue);
      const Store =
        order.storeName && order.storeName.toLowerCase().includes(searchValue);

      return inoMatches || customerNameMatches || customerPhoneMatches || Store;
    });
    this.page = 1;
    this.collectionSize = this.filteredOrders.length;
    this.refreshpagination();
    // this.collectionSize = this.EcomProductsdata.length;
    // this.page = 1;

  }

  orderId: number = null
  CustName: string = ''
  birthdayMsg: string = ''
  orderedDate: string = ''
  deliveredDate: string = ''

  //reset advance search
  searchTerm = "";
  Resetadv() {
    this.selectedDate = ''
    this.selectedDatedelivery = ''
    this.statusid = null
    this.searchTerm = "";
    this.storeid = 0;
    this.orderId = null
    this.CustName = ''
    this.birthdayMsg = ''
    this.orderedDate = ''
    this.deliveredDate = ''
    // console.log(this.statusid)
    // this.statusid = 0
    this.getOrdReport()
  }


  // str advance search
  selected(e: any) {
    this.storeid = e.id;
  }

  //date


  onChangefromdate(event: { fromDateStr: string; toDateStr: string }) {
    this.fromDate = event.fromDateStr;
    this.toDate = event.toDateStr;
    // console.log(this.fromDate); // Should display in yyyy-MM-dd format
    // console.log(this.toDate);
  }



  onChangetodate(event: { fromDateStr: string; toDateStr: string }) {
    this.fromDate = event.fromDateStr;
    this.toDate = event.toDateStr;
    // console.log(this.toDate)

  }

  formatter = (result: any) => result.strname;

  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      distinctUntilChanged(),
      map((term) =>
        term.length < 1
          ? []
          : this.stores
            .filter(
              (v: any) =>
                v.strname.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
            .slice(0, 10)
      )
    );


  //stores

  filteredStores = [];
  GetAllStores() {
    this.auth.GetAllStores().subscribe((data: any) => {
      // console.log(data)
      this.stores = data;
      this.stores.forEach(element => {
        element.strname = element.name
      });
      this.filteredStores = this.stores;
      this.getOrdReport()
      // console.log(this.filteredStores)
    });
  }
  statusid: number = null

  SelectStatus(data) {
    // console.log(data);
    this.statusid = data
    // if (data === "null") {
    //   console.log('Fetching all orders...');
    //   this.updatedval = this.GetOrdersValues
    // } else {
    //   this.updatedval = this.filteredOrders.filter(x => x.osi == +data);
    //   console.log(this.updatedval);
    //   console.log('Filtered orders displayed');
    // }
  }


  getdata() {
    // const id = 2849057
    // this.auth.GettOrdDetails(id).subscribe(data => {
    //   console.log(data)
    // })
  }


  ExcelData: any = []
  submit: boolean = false
  exportJson(): void {
    this.submit = true
    this.ExcelData = []
    // console.log(this.filteredOrders)

    if (this.filteredOrders.length > 0) {

      this.filteredOrders.forEach((val: any) => {
        if (val.statusid == 0) {
          val.Status = 'Pending'
        }
        if (val.statusid == 1) {
          val.Status = 'Accepted'
        }
        if (val.statusid == 5) {
          val.Status = 'Completed'
        }
        if (val.statusid == -1) {
          val.Status = 'Cancelled'
        }
        val.storeName = this.stores.find(
          store => store.id === val.deliverystore
        )?.strname || "Unknown Store";

      });
      // console.log(this.filteredOrders)

      this.ExcelData = this.filteredOrders.map(item => ({

        InvoiceNo: item.invoice,
        OrderId: item.orderid,
        Orderdatetime: item.orderdatetime,
        Deliverydatetime: item.deliverydatetime,
        Customername: item.customername,
        Customerphone: item.customerphone,
        storeName: item.storeName,
        BillAmount: 0,
        PaidAmount: 0,
        Status: item.Status
      }));

      // console.log(this.ExcelData);
      this.auth.exportAsExcelFile(this.ExcelData, 'OrderReport');
    }
  }

  //recepit values fetch
  GetReceipt_WO(receiptval: any) {

    this.auth.GettOrdDetails(receiptval.orderid).subscribe(data => {
      // console.log(data)
      this.OrdDetails = data["orderDetails"]
      this.ProductDetails = data["productDetails"]
      this.CustomerDetails = data["customerDetails"]
      this.OrdDetails.forEach((val: any) => {
        val.storeName = this.stores.find(
          store => store.id === val.deliverystoreid
        )?.strname || "Unknown Store";

      });


      //  console.log(receiptval)
      let Customerdetails = this.CustomerDetails[0];
      let Productdetails = this.ProductDetails
      let Totalreceiptdetails = this.OrdDetails[0];

      let totalamnt = 0;

      Productdetails.forEach((x: any) => {
        totalamnt += x.pr;
      });
      console.log(totalamnt)



      console.log(Customerdetails)
      console.log(Productdetails)
      console.log(Totalreceiptdetails)
      //  let total_amount = sum(product["pr"] for product in Productdetails)
      let receipt = {
        CusName: Customerdetails.name,
        CusPhone: Customerdetails.phoneNo,
        orderdate: Totalreceiptdetails.oddt,
        deliverydate: Totalreceiptdetails.didt,
        totalamnt: totalamnt,
        // totalamnt: Totalreceiptdetails.billamt,
        paidamnt: Totalreceiptdetails.paidamt,
        totaltax: Totalreceiptdetails.ta,
        tax1: Totalreceiptdetails.ta / 2,
        tax2: Totalreceiptdetails.ta / 2,
        invno: Totalreceiptdetails.invoice,
        Company: "",
        Store: "",
        Address: "",
        City: "",
        ContactNo: "",
        GSTno: "",
        products: [],
        LiveOrder: Totalreceiptdetails,
      };
      // console.log(receipt)

      const storeDetails = this.stores.find(
        (store: any) => store.id === receiptval.deliverystore
      );
      // console.log(storeDetails)
      if (storeDetails) {
        // console.log(storeDetails)
        receipt.Company = storeDetails.c_Name;
        receipt.Store = storeDetails.strname;
        receipt.Address = storeDetails.address;
        receipt.City = storeDetails.city;
        receipt.ContactNo = storeDetails.contactNo;
        receipt.GSTno = storeDetails.gsTno;
      } else {
        console.log(
          "Store details not found for the given store Id:",
          receiptval.deliverystore
        );
      }

      receipt.products = Productdetails.map((temp: any) => ({
        productName: temp.name + " ",
        // + temp.product.wg_qtytxt,
        productTotal: temp.pr,
        productQuantity: temp.qy,
      }));

      this.GetReceiptValues = [receipt];
      // console.log(this.GetReceiptValues)
      let str = this.GetReceiptValues[0].invno.split("FBINV | ");
      str.shift();
      this.colorchageinvoice = str;
      this.printreceipt();
      this.savedfilename = receiptval.invoice;
    });
  }

  //receipt
  printhtmlstyle = `
<style>
  #printelement {
    width: 270px;
    font-family: monospace;
  }
  .header {
      text-align: center;
  }
  .item-table {
      width: 100%;
  }
  .text-right {
    text-align: right!important;
  }
  .text-left {
    text-align: left!important;
  }
  .text-center {
    text-align: center!important;
  }
  tr.nb, thead.nb {
      border-top: 0px;
      border-bottom: 0px;
  }
  table, h3 {
    empty-cells: inherit;
    font-family: monospace;
    //font-size: small;
    width: 290px;
    padding-left: 0px;
    border-collapse: collapse;
  }
  table, tr, td {
    border-bottom: 0;
  }
  hr {
    border-top: 1px dashed black;
  }
  tr.bt {
    border-top: 1px dashed black;
    border-bottom: 0px;
  }
  tr {
    padding-top: -5px;
  }

  thead.rd{
    border-top: 1px dashed black;
  }
  
</style>`;

  //print receipt
  printreceipt() {
    // console.log(this.GetReceiptValues[0])
    const storeId = this.GetReceiptValues[0]?.LiveOrder;
    // console.log(storeId)
    // console.log(this.stores)
    const storeDetails = this.stores.find((store) => store.id === storeId?.
      deliverystoreid
    );

    if (!storeDetails) {
      console.log(
        "Store details not found for the given store Id:",
        this.GetReceiptValues[0].si
      );
      return;
    }

    const receiptDetails = this.GetReceiptValues[0];
    console.log(receiptDetails)

    var printtemplate = `
  <div id="printelement" style="width: 350px; font-family: monospace; padding-top: 50px; padding-bottom: 50px">
    <div class="header" style="text-align: center;">
      <h3>${storeDetails.c_Name}</h3>
      <p>
        ${storeDetails.strname}, ${storeDetails.address}<br>
        ${receiptDetails.City}, ${receiptDetails.ContactNo}<br>
        GSTIN: ${storeDetails.gsTno}<br>
        <strong>Receipt: <span>FBINV | </span><span style="color: red;">${receiptDetails.LiveOrder.orderid
      }</span></strong><br>
        Order Date: ${moment(receiptDetails.orderdate).format("LLL")}<br>
        <span style="color:green">Delivery Date: ${moment(
        receiptDetails.deliverydate
      ).format("LLL")}</span><br>
        Customer Mobile: ${receiptDetails.CusPhone}<br>
        Customer Name: ${receiptDetails.CusName}
      </p>
    </div>

    <table class="item-table" style="width: 100%; empty-cells: inherit; font-family: monospace; width: 290px; margin: auto; border-collapse: collapse;">
      <thead class="nb" style="border-top: 0px; border-bottom: 0px; border-bottom: 0.5px solid grey;">
        <th class="text-left" style="width: 100px;text-align: left!important;" colspan="2"><strong>ITEM</strong></th>
        <th class="text-right" style="text-align: center!important;"><strong>QTY</strong></th>
        <th class="text-right"><strong>PRICE</strong></th>
      </thead>
      <tbody>`;

    this.GetReceiptValues.forEach((item: any) => {
      item.products.forEach((product: any) => {

        printtemplate += `
          <tr class="nb" style="border-top: 0px; border-bottom: 0px; border-bottom: 0.5px solid grey;">
            <td class="text-left" style="width: 100px;text-align: left!important;" colspan="2">${product.productName
          }</td>
            <td class="text-right" style="text-align: center!important;">${product.productQuantity
          }</td>
            <td class="text-right">${product.productTotal.toFixed(2)}</td>
          </tr>`;
      });
    });
    printtemplate += `
    <tr class="nb">
      <td class="text-left"><strong>Paid</strong></td>
      <td colspan="2"></td>
      <td class="text-right">${receiptDetails.paidamnt.toFixed(2)}</td>
    </tr>
    <tr class="nb">
      <td class="text-left"><strong>Total</strong></td>
      <td colspan="2"></td>
      <td class="text-right">${receiptDetails.totalamnt.toFixed(2)}</td>
    </tr>
    <tr class="nb" ${receiptDetails.totalamnt - receiptDetails.paidamnt > 0 ? "" : "hidden"
      }>
      <td class="text-left"><strong>Balance</strong></td>
      <td colspan="2"></td>
      <td class="text-right">${(
        receiptDetails.totalamnt - receiptDetails.paidamnt
      ).toFixed(2)}</td>
    </tr>
    </tbody>
    </table>
    <hr>
    <div class="text-center">
      <p>Powered By Biz1Book.</p>
    </div>
  </div>`;
    document.body.insertAdjacentHTML("beforeend", printtemplate);
    const newPrintElement = document.getElementById("printelement");
    if (newPrintElement) {
      html2canvas(newPrintElement).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = `${this.savedfilename}.png`;
        link.click();
        newPrintElement.remove();
      });
    } else {
      // console.error("Print element with id 'printelement' not found.");
    }
  }

  restrictInput(event: KeyboardEvent) {
  if (event.key === 'e' || event.key === 'E') {
    event.preventDefault();
  }
}

}
