import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Subject } from 'rxjs/internal/Subject';
import { NotificationsService } from '../../notifications/notifications.service';
import { AuthService } from '../../auth.service';
@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  url = 'https://uat-ecom.azurewebsites.net/'
  private hubConnection: signalR.HubConnection;

  hubconnection: signalR.HubConnection = new signalR.HubConnectionBuilder()
    .withUrl(this.url + 'ecomhub')
    .withAutomaticReconnect([0, 1000, 5000, 10000])
    .configureLogging(signalR.LogLevel.Information)
    .build()

  constructor(private notification: NotificationsService) {

  }

  connect() {
    if (this.hubconnection.state !== signalR.HubConnectionState.Disconnected) {
      return;
    }
  
    if (navigator.onLine) {
      this.hubconnection
        .start()
        .then(() => {
          // console.log('SignalR connected:', this.hubconnection.state);
          this.addWOOrderListener()
        })
        .catch(err => {
          // console.error('Error connecting to SignalR:', err);
          setTimeout(() => {
            this.connect();
          }, 2000);
        });
    } else {
      // console.warn('Offline or logInfo missing. Retrying connection...');
      setTimeout(() => {
        this.connect();
      }, 2000);
    }
  }

  // startConnection() {
  //   // const url = 'https://localhost:44346/ecomhub';
  //   const url = 'https://uat-ecom.azurewebsites.net/ecomhub';
  //   let builder = new signalR.HubConnectionBuilder();

  //   if (url.startsWith('https')) {
  //     builder = builder.withUrl(url, {
  //       skipNegotiation: true,
  //       transport: signalR.HttpTransportType.WebSockets
  //     });
  //   } else {
  //     builder = builder.withUrl(url);
  //   }

  //   this.hubConnection = builder
  //     .withAutomaticReconnect([2000, 5000, 10000])
  //     .configureLogging(signalR.LogLevel.Information)
  //     .build();

  //   this.hubConnection.onclose(err => {
  //     console.error('Connection closed with error: ', err);
  //     setTimeout(() => this.startConnection(), 2000);
  //   });

  //   this.hubConnection.onreconnecting(err => {
  //     console.warn('Connection lost. Attempting to reconnect...', err);
  //   });

  //   this.hubConnection.onreconnected(connectionId => {
  //     console.log('Reconnected successfully. Connection ID: ', connectionId);
  //   });

  //   this.hubConnection.start().then(() => {
  //     this.EcomrderListener()

  //     console.log('Connection started');
  //   }).catch(err => {
  //     console.error('Error while starting connection: ', err);
  //   });
  // }

  addOrderListener(callback: (storeId: number) => void) {
    this.hubConnection.on('EcomOrder', (storeId: number) => {
      // console.log('Received storeId: ', storeId);
      callback(storeId);
    });
  }


  private newOrderSubject = new Subject<any>()

  sendNewOrder(orderId: number) {
    this.newOrderSubject.next({ orderId })
  }

  getNewOrder() {
    return this.newOrderSubject.asObservable()
  }

  public EcomrderListener = () => {
    this.hubConnection.on('EcomOrder', (orderId: number) => {
      // this.playsound()
      // console.log(
      //   `order ID: ${orderId}`,
      // )
      // console.log(orderId)
      if (orderId > 0) {
        this.sendNewOrder(orderId)
        console.log('Sound ON')
        var notificationobj = {
          title: `New Ecom Order!`,
          subtitle: 'PLease accept Order',
          body: 'A new online order hs been placed',
          other: { platform: 'ENQ' },
        }
        this.notification.startnotificationsound()
        this.notification.notify(notificationobj)
      } else {
        this.notification.stopnotificationsound()
        console.log('Sound OFF')
      }

     
    })
  }

  public addWOOrderListener = () => {
    this.hubconnection.on('EcomOrder', (orderId: string) => {

      console.log(orderId)
      const ord = Number(orderId)
      if (ord > 0) {
        this.sendNewOrder(ord)
        console.log('Sound ON')
        var notificationobj = {
          title: `New Ecom Order!`,
          subtitle: 'PLease accept Order',
          body: 'A new online order hs been placed',
          other: { platform: 'ENQ' },
        }
        this.notification.startnotificationsound()
        this.notification.notify(notificationobj)
      } else {
        this.notification.stopnotificationsound()
        console.log('Sound OFF')
      }
    })
  }




}

