<div class="page-wrapper">

    <div class="imagebox">
        <!-- <div>
            <h2 style="font-weight: 700;">Welcome to Admin Dashboard</h2>

        </div> -->
        <div class="authentication-box" style="position: absolute;">
            <div class="container">
                <div class="row" style="justify-content: center;">
                    <div class="col-md-7 card-right">
                        <div class="card tab2-card">
                            <div class="card-body">
                                <div class="text-center">
                                    <h2 class="form-title">Sign In</h2>
                                </div>
                                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()"
                                    class="form-horizontal auth-form mt-4" novalidate>
                                    <div class="form-group">
                                        <label for="email" class="f-18">Email:</label>
                                        <input id="email" formControlName="Email" type="email" class="form-control"
                                            autocomplete="off" aria-label="Email" />
                                        <small
                                            *ngIf="loginForm.get('Email')?.touched && loginForm.get('Email')?.hasError('required')"
                                            class="text-danger">
                                            Email is required
                                        </small>
                                        <small
                                            *ngIf="loginForm.get('Email')?.touched && loginForm.get('Email')?.hasError('email')"
                                            class="text-danger">
                                            Please enter a valid email
                                        </small>
                                    </div>

                                    <div class="form-group position-relative">
                                        <label for="password" class="f-18">Password:</label>
                                        <input id="password" formControlName="password"
                                            [type]="isPasswordVisible ? 'text' : 'password'" class="form-control"
                                            aria-label="Password" />
                                        <i [class]="isPasswordVisible ? 'fa fa-eye-slash' : 'fa fa-eye'"
                                            class="toggle-password-icon" (click)="togglePasswordVisibility()"
                                            aria-hidden="true"></i>
                                        <small
                                            *ngIf="loginForm.get('password')?.touched && loginForm.get('password')?.hasError('required')"
                                            class="text-danger">
                                            Password is required
                                        </small>
                                        <small
                                            *ngIf="loginForm.get('password')?.touched && loginForm.get('password')?.hasError('minlength')"
                                            class="text-danger">
                                            Password must be at least 6 characters long
                                        </small>
                                    </div>

                                    <!-- <div class="form-terms d-flex align-items-center">
                                        <a href="javascript:void(0)" class="btn btn-default forgot-pass ms-auto">
                                            Forgot password
                                        </a>
                                    </div> -->
                                    <div class="form-button text-center">
                                        <button class="btn btn-primary" type="submit">
                                            Login
                                        </button>
                                    </div>
                                    <hr />
                                    <!-- <div class="text-center">
                                        <span>Don't have an account? <a [routerLink]="'/registration'">Sign
                                                up</a></span>
                                    </div> -->
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div style="display: none;    z-index: 999;
    position: fixed;" id="logloadlogin">
    <div class="bouncing-loader">
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>