import { Injectable } from '@angular/core';
import { Howl, Howler } from 'howler'
import { ToastrService } from 'ngx-toastr';
import * as path from 'path';
import { NotificationContainer, NotificationManager } from 'react-notifications';
@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  notificationsound: Howl
  constructor(private toastr: ToastrService) {
    this.notificationsound = new Howl({
      src: ['/assets/audio/army.mp3'],
      loop: true,
    });

  }

  startnotificationsound() {
    console.log('START NOTIFICATION')
    this.notificationsound.stop()
    this.notificationsound.play()
  }


  stopnotificationsound() {
    this.notificationsound.stop()
    console.log('Sound Stoped')
  }


  notify(notificationobj: any) {
    console.log(notificationobj);
    let imageurl = '';

    // Check for platform and set image path
    if (notificationobj?.other?.platform === 'ENQ') {
      imageurl = 'assets/images/logobiz1.png';
    }

    // Define notification options
    const options = {
      title: notificationobj.title || 'Default Title',
      subtitle: notificationobj.subtitle || 'Default Subtitle',
      body: notificationobj.body || 'Default Body',
      silent: false,
      icon: imageurl,
      actions: [
        {
          action: 'showButton',
          title: 'Show Button',
        },
      ],
    };

    // If Toastr service is used
    if (this.toastr) {
      console.log('1111111111111111')
      this.toastr.success(options.body, options.title);
      this.startnotificationsound()
    } else {
      this.stopnotificationsound()
    }
  }







}
