import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavService } from '../../service/nav.service';
import { NotificationsService } from 'src/app/components/notifications/notifications.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile: boolean;

  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(public navServices: NavService, private ns: NotificationsService, private router: Router,) { }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }
  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  stopnotificationsound() {
    // console.log('click Sound')
    this.ns.stopnotificationsound()
  }

  Lock() {
    sessionStorage.removeItem('userSettindata');
    sessionStorage.removeItem('User');
    
    this.router.navigateByUrl('/pinscreen')
  }

  Logout() {
    sessionStorage.removeItem('userSettindata');
    sessionStorage.removeItem('User');

    this.router.navigateByUrl('/login')

  }
  user: any = []
  ngOnInit() {
    const user = JSON.parse(sessionStorage.getItem('User') || '{}');
    // console.log(user)

  }

}
