import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';

import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SharedModule } from '../../shared/shared.module';
import { PinscreenComponent } from './pinscreen/pinscreen.component';
import { RegistrationComponent } from './registration/registration.component';
import { UnauthroizedComponent } from './unauthroized/unauthroized.component';


@NgModule({
  declarations: [LoginComponent, PinscreenComponent, RegistrationComponent, UnauthroizedComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    CarouselModule,
    SharedModule,
    NgbModule
  ]
})
export class AuthModule { }
