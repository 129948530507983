import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  isPasswordVisible = false;

  constructor(private fb: FormBuilder, private router: Router, private authService: AuthService,private toastr: ToastrService) {
    this.loginForm = this.fb.group({
      Email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  onSubmit() {
    if (this.loginForm.valid) {
      if (this.loginForm.valid) {
        const email = this.loginForm.get('Email')?.value;
        const password = this.loginForm.get('password')?.value;
        // console.log('Form values:', { email, password });
             document.getElementById('logloadlogin').style.display = 'block'
        this.authService.Login(email, password).subscribe((data: any) => {
          // console.log(data)

          if (data.status == 200) {
             document.getElementById('logloadlogin').style.display = 'none'
            const id = data.id
            sessionStorage.setItem("accId", id);
            sessionStorage.setItem('logState', 'logged_in')
            // console.log('Form submitted successfully:', this.loginForm.value);
            this.router.navigate(['/pinscreen']);

          } else {
            // alert('Invalid credentials');
            document.getElementById('logloadlogin').style.display = 'none'
             this.toastr.error('Error', 'Invalid credentials')   
          }
        })

        // Perform login logic here
      } else {
        // console.log('Form is invalid!');
        this.loginForm.markAllAsTouched(); // Mark all fields as touched to show errors
      }
    }

  }
}