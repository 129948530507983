
<div class="container-fluid">
  <div class="page-header">
    <div class="row">
      <div class="col-lg-6">
        <div class="page-header-left">
          <h3>
             ADD CATEGORY
  
          </h3>
        </div>
      </div>
    
    </div>
  </div>
</div>
<!-- Container-fluid starts-->
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Products Category</h5>
        </div>
        <div class="card-body">
          <div class="btn-popup pull-right">
            <button
              type="button"
              class="btn btn-primary"
              data-toggle="modal"
              data-original-title="test"
              data-target="#exampleModal"
              (click)="open(content)"
            >
              Add Category
            </button>
            <ng-template #content let-modal>
              <div class="modal-header">
                <h5 class="modal-title f-w-600" id="exampleModalLabel">
                  Add Physical Product
                </h5>
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  (click)="modal.dismiss('Cross click')"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form class="needs-validation">
                  <div class="form">
                    <div class="form-group">
                      <label for="validationCustom01" class="mb-1"
                        >Category Name :</label
                      >
                      <input
                        class="form-control"
                        id="validationCustom01"
                        type="text"
                      />
                    </div>
                    <div class="form-group mb-0">
                      <label for="validationCustom02" class="mb-1"
                        >Category Image :</label
                      >
                      <input
                        class="form-control"
                        id="validationCustom02"
                        type="file"
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary">Save</button>
                <button
                  class="btn btn-secondary"
                  type="button"
                  data-dismiss="modal"
                  (click)="modal.dismiss('Cross click')"
                >
                  Close
                </button>
              </div>
            </ng-template>
          </div>
          <div id="batchDelete" class="category-table custom-datatable">
            <div class="table-responsive">
              <!-- <form>
                                <div class="mb-3 row">
                                    <label for="table-complete-search" class="col-xs-3 col-sm-auto col-form-label">Full text search:</label>
                                    <div class="col-xs-3 col-sm-auto">
                                      <input id="table-complete-search" type="text" class="form-control" name="searchTerm"  [(ngModel)]="searchText" autocomplete="off">
                                    </div>
                                </div>
                              </form> -->
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col" sortable="img" (sort)="onSort($event)">
                      Image
                    </th>
                    <th
                      scope="col"
                      sortable="product_name"
                      (sort)="onSort($event)"
                    >
                      Name
                    </th>
                    <th scope="col" sortable="price" (sort)="onSort($event)">
                      Price
                    </th>
                    <th scope="col" sortable="status" (sort)="onSort($event)">
                      Status
                    </th>
                    <th scope="col" sortable="category" (sort)="onSort($event)">
                      Category
                    </th>
                    <th
                      scope="col"
                      sortable="population"
                      (sort)="onSort($event)"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="
                      let catgory of tableItem$ | async | filter : searchText
                    "
                  >
                    <th scope="row">{{ catgory.id }}</th>
                    <td>
                      <img
                        [src]="catgory.img"
                        class="imgTable"
                        style="width: 20px"
                      />
                    </td>
                    <td>{{ catgory.product_name }}</td>
                    <td>{{ catgory.price }}</td>
                    <td>
                      <i class="fa fa-circle {{ catgory.status }} f-12"></i>
                    </td>
                    <td>{{ catgory.category }}</td>
                    <td>
                      <a href="javascript:void(0)"
                        ><i class="fa fa-edit f-12"></i></a
                      >&nbsp;
                      <a href="javascript:void(0)"
                        ><i class="fa fa-trash-o"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-center p-2">
                <ngb-pagination
                  [collectionSize]="(total$ | async)!"
                  [(page)]="service.page"
                  [pageSize]="service.pageSize"
                >
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
