<div class="form-group position-relative">
  <div class="input-group">
    <input type="text" class="form-control" placeholder="Select Date" id="datePickerInput" (click)="openDatePicker()"
      [value]="
          (fromDate ? formatDate(fromDate) : '') +
          (fromDate ? ' / ' : '') +
          (toDate ? formatDate(toDate) : '')
        " autocomplete="off" />
    <div class="input-group-append">
      <button class="btn btn-primary" type="button" *ngIf="isopen">
        <i class="fa fa-calendar" aria-hidden="true"></i>
      </button>
      <button class="btn btn-primary" type="button" (click)="clearval()" *ngIf="!isopen">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div *ngIf="datePickerOpened" class="datepicker-container-s border rounded">
    <ngb-datepicker #dp1 ngModel (ngModelChange)="onDateChange($event)" [displayMonths]="2" [dayTemplate]="t">
    </ngb-datepicker>

    <!-- Today, Yesterday, Last 7 days buttons -->
    <div class="text-center mt-2 mb-2">
      <button class="btn btn-primary mr-2" type="button" (click)="selectToday()" style="margin: 6px;">
        Today
      </button>
      <button class="btn btn-primary mr-2" type="button" (click)="selectYesterday()" style="margin: 6px;">
        Yesterday
      </button>
      <button class="btn btn-primary" type="button" (click)="selectLast7Days()" style="margin: 6px;">
        Last 7 days
      </button>
    </div>

    <ng-template #t let-date="date" let-focused="focused">
      <span class="custom-day" [class.focused]="focused" [class.range]="
            isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
        [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null" (dblclick)="setFromDateToDate(date)">
        {{ date.day }}
      </span>
    </ng-template>
  </div>
</div>