
<div class="container-fluid">
    <div class="page-header">
      <div class="row">
        <div class="col-lg-6">
          <div class="page-header-left">
            <h3>
              ORDER REPORT
    
            </h3>
          </div>
        </div>
      
      </div>
    </div>
  </div>
<!-- Container-fluid starts-->
<div class="container-fluid" id="ordreport_index">
    <div class="row">

        <!-- Advance Search -->

        <div class="card" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
            <div class="card-content">
                <div class="card-body">
                    <div class="row">
                        <div class="col-3">
                            <h5>Order-Id</h5>
                            <input type="number" class="form-control" [(ngModel)]="orderId" 
       (keypress)="restrictInput($event)">

                        </div>
           
                        <div class="col-3">
                            <h5>Delivery Store</h5>
                            <ng-template #rt let-r="result" let-t="term">
                                <ngb-highlight [result]="r.strname" [term]="t"></ngb-highlight>
                            </ng-template>
                            <input id="typeahead-template" type="text" class="form-control col" [(ngModel)]="searchTerm"
                                [ngbTypeahead]="search" [resultTemplate]="rt" [inputFormatter]="formatter"
                                placeholder="Search Stores..." (selectItem)="selected($event.item)" />

                        </div>

                        <div class="col-3">
                            <h5>Status</h5>
                            <select class="custom-select form-select" required [(ngModel)]="statusid"
                                (change)="SelectStatus($event.target.value)">
                                <option value="null">--All--</option>
                                <option value="5">Completed</option>
                                <option value="0">Pending</option>
                                <option value="1">Accepted</option>
                                <option value="4">Out For delivery</option>
                                <option value="-1">Cancelled</option>
                                <option value="-5">Cancel Requesting</option>
                            </select>
                        </div>
                        <div class="col-3">
                            <h5>Ordered Date</h5>
                            <div class="input-group input-grp-p">
                                <input class="datepicker-here form-control digits" ngbDatepicker #f="ngbDatepicker"
                                    [(ngModel)]="selectedDate" (dateSelect)="onDateChange($event)" />
                                <div class="input-group-append">
                                    <button class="btn calendar" (click)="f.toggle()" type="button"><i
                                            class="fa fa-calendar"></i></button>
                                </div>
                            </div>

                        </div>
                        <div class="col-3 mt-2">
                            <h5>Delivered Date</h5>
                            <div class="input-group input-grp-p">
                                <input class="datepicker-here form-control digits" ngbDatepicker #d="ngbDatepicker"
                                    [(ngModel)]="selectedDatedelivery" (dateSelect)="onDateChangedelivery($event)" />
                                <div class="input-group-append">
                                    <button class="btn calendar" (click)="d.toggle()" type="button"><i
                                            class="fa fa-calendar"></i></button>
                                </div>
                            </div>

                        </div>




                        <div class="col-3" style="text-align: right; margin-top: 30px">
                            <button class="btn btn-primary" (click)="Resetadv()">
                                Reset
                            </button>
                            &nbsp;
                            <button class="btn btn-success" (click)=" getOrdReport()">
                                Fetch
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
        <div class="col-sm-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-4">
                            <input type="text" class="form-control" id="iconLeft2" placeholder="Search Invoice..."
                                [(ngModel)]="SearchValues" (input)="searchTable()" autocomplete="off" />
                            <div class="form-control-position">
                                <i class="ft-search"></i>
                            </div>
                        </div>
                        <div class="form-group  col-5">
                            <button class="btn btn-md btn-outline-info w-auto" (click)="exportJson()">Export to Excel <i
                                    class="fa fa-file-excel-o" aria-hidden="true"></i></button>
                            <span class="m-15" *ngIf="submit == true && filteredOrders.length == 0"
                                style="color: red;">No
                                records found in the table</span>
                        </div>
                        <div class="col-3" style="text-align: right">
                            <button class="btn btn-warning" (click)="isCollapsed = !isCollapsed"
                                [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                                Advance Search
                            </button>
                        </div>
                    </div> <br>
                    <div class="table-responsive"  >
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th style="background-color: #343a40; color: white">#</th>
                                    <th style="
                                  background-color: #343a40;
                                  color: white;
                                  cursor: pointer;
                                " (click)="sortData('InvoiceNo')">
                                        InvoiceNo
                                        <span *ngIf="sortField === 'InvoiceNo'">
                                            <i class="fa" [ngClass]="sortOrder ? 'fa-sort-up' : 'fa-sort-down'"
                                                style="font-size: 20px" aria-hidden="true"></i>
                                        </span>
                                    </th>
                                    <th style="
                                  background-color: #343a40;
                                  color: white;
                                  cursor: pointer;
                                " (click)="sortData('Customer')">
                                        Customer
                                        <span *ngIf="sortField === 'Customer'">
                                            <i class="fa" [ngClass]="sortOrder ? 'fa-sort-up' : 'fa-sort-down'"
                                                style="font-size: 20px" aria-hidden="true"></i>
                                        </span>
                                    </th>
                                    <th style="
                                  background-color: #343a40;
                                  color: white;
                                  cursor: pointer;
                                " (click)="sortData('OrderedDate')">
                                        Ordered Date
                                        <span *ngIf="sortField === 'OrderedDate'">
                                            <i class="fa" [ngClass]="sortOrder ? 'fa-sort-up' : 'fa-sort-down'"
                                                style="font-size: 20px" aria-hidden="true"></i>
                                        </span>
                                    </th>
                                    <th style="
                                  background-color: #343a40;
                                  color: white;
                                  cursor: pointer;
                                " (click)="sortData('DeliveryDate')">
                                        Delivery Date
                                        <span *ngIf="sortField === 'DeliveryDate'">
                                            <i class="fa" [ngClass]="sortOrder ? 'fa-sort-up' : 'fa-sort-down'"
                                                style="font-size: 20px" aria-hidden="true"></i>
                                        </span>
                                    </th>
                                    <th style="
                                  background-color: #343a40;
                                  color: white;
                                  cursor: pointer;
                                " (click)="sortData('Store')">
                                        Store
                                        <span *ngIf="sortField === 'Store'">
                                            <i class="fa" [ngClass]="sortOrder ? 'fa-sort-up' : 'fa-sort-down'"
                                                style="font-size: 20px" aria-hidden="true"></i>
                                        </span>
                                    </th>
                                    <th style="
                                  background-color: #343a40;
                                  color: white;
                                  cursor: pointer;
                                " (click)="sortData('Status')">
                                        Status
                                        <span *ngIf="sortField === 'Status'">
                                            <i class="fa" [ngClass]="sortOrder ? 'fa-sort-up' : 'fa-sort-down'"
                                                style="font-size: 20px" aria-hidden="true"></i>
                                        </span>
                                    </th>
                                    <th style="background-color: #343a40; color: white">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let ord of updatedval; let i = index">
                                    <td>{{ ord.Sno }}</td>
                                    <td>
                                        <a href="javascript:;" class="warning p-0"
                                            (click)="OpenOrderdetails(content, ord)">{{ ord.invoice }}|{{ord.id }}
                                        </a>
                                    </td>
                                    <td>
                                        <div class="row">
                                            <div class="col">
                                                <span>
                                                    <div class="containericon">
                                                        <i data-feather="user" style="color: #343a40; height: 15px"></i>
                                                        <span>&nbsp;{{
                                                            ord.customername
                                                            || "-"
                                                            }}</span>
                                                    </div>
                                                </span>

                                                <span>
                                                    <app-feather-icons style="display: none" [icon]="'user'"
                                                        class="font-secondary"></app-feather-icons>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <span>
                                                    <div class="containericon">
                                                        <i data-feather="phone"
                                                            style="color: #62c73e; height: 15px"></i>
                                                        <span style="color: #62c73e">&nbsp;{{
                                                            ord.customerphone
                                                            || "-"
                                                            }}</span>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{ ord.orderdatetime | date:'MMMM d, y h:mm a'}}</td>
                                    <td>{{ ord.deliverydatetime | date:'MMMM d, y h:mm a' }}</td>
                                    <td>
                                        <span *ngIf="ord.storeName == null" style="color: red">No Store Selected</span>
                                        <span *ngIf="ord.storeName != null" style="color: green">{{ ord.storeName }}
                                        </span>
                                    </td>
                                    <td>
                                        <span *ngIf="ord.statusid == 0" class="badge badge-warning">Pending</span>
                                        <span *ngIf="ord.statusid == 1" class="badge badge-dark">Accepted</span>
                                        <span *ngIf="ord.statusid == 5" class="badge badge-success">Completed</span>
                                        <span *ngIf="ord.statusid == -1" class="badge badge-danger"
                                            style="background-color: red;">Cancelled</span>
                                                      <span *ngIf="ord.statusid == 4" class="badge badge-danger"
                                            style="background-color: rgb(0, 140, 255);">Out For delivery</span>

                                            <span *ngIf="ord.statusid == -5" class="badge badge-danger"
                                            style="background-color: rgb(255, 81, 0);">Cancel Requesting</span>

                                    </td>
                                    <td>

                                        &nbsp;
                                        <a href="javascript:void(0)">
                                            <i class="fa fa-print f-12" style="font-size: 20px; color: grey"
                                                placement="top" ngbTooltip="Receipt" (click)="GetReceipt_WO(ord)"></i></a>
                                    </td>

                                   
                                </tr>
                            </tbody>
                            <tbody *ngIf="updatedval.length == 0">
                                <tr>
                                    <td colspan="100%" class="no-data-container">
                                        <i class="fa fa-database" aria-hidden="true"></i>
                                        <p>No data available</p>
                                    </td>
                                </tr>
                            </tbody>
                            
                          
                        </table>
                        <br />
                        <div class="d-flex justify-content-center p-2">
                            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                                (pageChange)="refreshpagination()">
                            </ngb-pagination>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<ng-template #noData>
    <div class="no-data-container">
        <i class="fa fa-database" aria-hidden="true"></i>
        <p>No data available</p>
    </div>
</ng-template>

<!-- modal content order Deails -->

<ng-template #content let-c="close" let-d="dismiss" id="ordrep_edit">
    <div id="content-to-blur1">
        <div class="modal-header" *ngIf="OrdDetails" style="align-items: center">
            <div class="row" style="display: contents">
                <div class="col-6">
                    <p class="modal-title" style="font-size: larger">
                      <b>Order Details |</b>
                      <a href="javascript:;" class="r p-0" style="font-weight: 600" style="text-decoration: none">
                        # {{ OrdDetails[0].invoice }} | {{OrdDetails[0].id}} 
                      </a>
            
                      <a href="javascript:void(0)" (click)="GetReceipt_WO(OrdDetails[0])">
                        <i class="fa fa-print" style="font-size: 20px; color: grey;" ngbTooltip="Receipt"></i>
                        <span> Print</span> </a>
                    </p>
            
                    <div style="font-weight: bold; " [ngClass]="{'text-success':OrdDetails[0].status==5,
                      'text-warning' :OrdDetails[0].status==0,
                      'text-info':OrdDetails[0].status==1,
                      'text-primary':OrdDetails[0].status==4}">
                      <b style="
                                color: #777777;
                                letter-spacing: 0.7px;
                                font-size: larger;
                                font-weight: bold;
                              "> Status : </b>{{ getStatusText(OrdDetails[0].status) }}
                    </div>
                  </div>
                <div class="col-6" style="text-align: end">
                    <button id="closebtnhaa" style="border-radius: 100px; color: white; padding: 1px" type="button"
                        aria-label="Close" class="close btn btn-primary btn-sm m-1" (click)="onDismiss('Cross click')">
                        <i class="fa fa-times" aria-hidden="true" style="
                  color: #ffff;
                  font-size: large;
                  margin-top: 4px;
                  margin-bottom: 4px;
                  margin-left: 6px;
                  margin-right: 6px; 
                "></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-body">
            <div style="
            border: 1px solid #ffffff;
            margin: 5px;
            border-radius: 5px;
            padding-left: 18px;
            padding-right: 18px;
          ">
                <div class="card overflow-hidden card-outline-success" style="box-shadow: -1px 1px 9px -3px">
                    <div class="card-body" style="padding: 15px">
                        <div class="row mb-3">
                            <div class="col-4" style="margin-top: 20px">
                                <p>
                                    <!-- <strong> Bill Amount : </strong>
                                    <strong> &#x20b9;{{ OrdDetails[0].billamt }}</strong> -->
                                    <strong style="font-size: 14px;"> Bill Amount <sup style="color: red;">*(includes
                                            tax)</sup> :
                                    </strong>
                                    <strong style="font-size: 16px;"> &#x20b9;{{ OrdDetails[0].billamt }}</strong>

                                </p>
                        
                            </div>
                            <div class="col-4"></div>
                            <div class="col-4">
                                <p style="text-align: right; margin-top: 20px">
                                    <strong> Paid Amount : </strong>
                                    <strong style="font-size: 16px;"> &#x20b9;{{ OrdDetails[0].billamt }}</strong>
                                    <!-- <strong *ngIf="OrdDetails.ts != 1"> &#x20b9;{{ OrdDetails.paidamt }}</strong>
                                    <strong *ngIf="OrdDetails.ts == 1"> &#x20b9;{{ OrdDetails.billamt }}</strong> -->

                                </p>

                                <p style="text-align: right; margin-top: 20px">
                                    <strong> Payment Method : </strong>
                                    <span style="font-size: 14px;" *ngIf="OrdDetails[0].pti == 1">Online
                                        Payment</span>
                                    <span style="font-size: 14px;" *ngIf="OrdDetails[0].pti == 2">Pay On
                                        Delivery</span><br>
                                </p>

                                <p style="text-align: right; margin-top: 20px">
                                    <strong> Payment Status : </strong>
                                    <span style="font-size: 14px;" *ngIf="OrdDetails[0].ts == 1"
                                        style="color: green;">Payment
                                        Success
                                    </span>
                                    <span style="font-size: 14px;" *ngIf="OrdDetails[0].ts == -1"
                                        style="color: red;">Payment
                                        Failed</span>

                                    <span style="font-size: 14px;" *ngIf="OrdDetails[0].ts == 0"
                                        style="color: rgb(228, 166, 33);">Payment
                                        Pending</span>
                                    <br>
                                </p>

                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-4"></div>
                            <div class="col-4"></div>
                            <div class="col-4">
                                <p style="text-align: right">
                                    <strong> Delivery Store : </strong>
                                    <span style="color: red">
                                        <strong *ngIf="OrdDetails[0].storeName != null">{{
                                            OrdDetails[0].storeName
                                            }}</strong>
                                        <strong *ngIf="OrdDetails[0].storeName == null">
                                            No Store Selected</strong></span>
                                </p>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table table table-sm m-0">
                                <thead>
                                    <tr class="table-primary">
                                        <th scope="col" style="
                          text-align: center;
                          top: 0;
                          background-color: #ff8084;
                          color: white;
                          z-index: 1;
                          position: sticky;
                        ">
                                            #
                                        </th>
                                        <th scope="col" style="
                          text-align: center;
                          top: 0;
                          background-color: #ff8084;
                          color: white;
                          z-index: 1;
                          position: sticky;
                        ">
                                            Image
                                        </th>
                                        <th scope="col" style="
                          text-align: center;
                          top: 0;
                          background-color: #ff8084;
                          color: white;
                          z-index: 1;
                          position: sticky;
                        ">
                                            Item Name
                                        </th>
                                        <th scope="col" style="
                          text-align: center;
                          top: 0;
                          background-color: #ff8084;
                          color: white;
                          z-index: 1;
                          position: sticky;
                        ">
                                            Quantity
                                        </th>
                                        <th scope="col" style="
                          text-align: center;
                          top: 0;
                          background-color: #ff8084;
                          color: white;
                          z-index: 1;
                          position: sticky;
                        ">
                                            Message
                                        </th>
                                        <th scope="col" style="
                          text-align: center;
                          top: 0;
                          background-color: #ff8084;
                          color: white;
                          z-index: 1;
                          position: sticky;
                        ">
                                            Price
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let ordt of ProductDetails; let i = index">
                                        <th scope="row" style="align-content: center; text-align: center">
                                            {{ i + 1 }}
                                        </th>
                                        <td>
                                            <div class="text-center">
                                                <img src="{{ ordt.imgUrl }}" class="rounded" alt="Product Image"
                                                    style="width: 50px; height: 50px; cursor: pointer" (click)=" openImgModal(customModal, ordt.imgUrl)
                            " />
                                            </div>
                                        </td>
                                        <td style="align-content: center; text-align: center">
                                            {{ ordt.name}}
                                            <!-- <span *ngIf="ordt.product.wg_qtytxt">({{ ordt.product.wg_qtytxt }})</span>
                                            <p *ngIf="ordt.product.v_name" style="color: royalblue">
                                                ({{ ordt.product.v_name }})
                                            </p> -->
                                        </td>
                                        <td style="align-content: center; text-align: center">
                                            {{ ordt.qy }}
                                        </td>
                                        <td style="align-content: center; text-align: center">
                                            {{ ordt.msg }}
                                        </td>
                                        <td style="align-content: center; text-align: center">
                                            <i class="fa fa-inr" aria-hidden="true" style="font-size: small"></i>
                                            {{ ordt.pr }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="row">
                            <div class="col" style="text-align: left">
                                <div *ngIf="Note != ''" style="
                      color: red;
                      margin-top: 12px;
                      margin-right: 15px;
                      margin-bottom: 15px;
                    ">
                                   <strong *ngIf="Note.note">* Note :</strong> &nbsp;<span style="color: #182c61">{{ Note.note }}</span><br>
                                   <strong  *ngIf="Note.cancel_status"> * Cancel Status :</strong> &nbsp;<span style="color: #182c61">{{ Note.cancel_status }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="opacity: 1.08; color: #dee2e6" />
                <!-- modal content contacts -->
                <div class="card overflow-hidden card-outline-success" style="box-shadow: -1px 1px 9px -3px">
                    <div class="card-body" style="padding: 15px">
                        <div class="row">
                            <div class="col-6">
                                <strong> Name : </strong>&nbsp;
                                <span>{{ CustomerDetails[0].name }}</span>
                            </div>
                            <div class="col-6" style="text-align: right">
                                <strong>Ordered Date : </strong>&nbsp;<span>
                                    {{ OrdDetails[0].oddt | date: 'MMMM d, yyyy h:mm a' }}</span>
                            </div>
                        </div>
                        &nbsp;
                        <div class="row">
                            <div class="col-6">
                                <strong>Phone No : </strong>&nbsp;
                                <span>{{ CustomerDetails[0].phoneNo }}</span>
                            </div>
                            <div class="col-6" style="text-align: right">
                                <strong>Delivery Date : </strong>&nbsp;<span>{{
                                    OrdDetails[0].didt | date: 'MMMM d, yyyy h:mm a'
                                    }}</span>
                            </div>
                        </div>
                        &nbsp;
                        <div class="row">
                            <div class="col">
                                <strong>Address : </strong>&nbsp;<span>{{
                                    CustomerDetails[0].address
                                    }}</span>
                            </div>
                        </div>
                        &nbsp;
                        <br />

                        <!-- modal content transactions -->
                        <div class="table-responsive" style="height: 136px; padding: 0px" *ngIf="trans">
                            <table class="table table table-sm m-0">
                                <thead>
                                    <tr class="table-primary">
                                        <th scope="col" style="
                          text-align: center;
                          top: 0;
                          background-color: #ff8084;
                          color: white;
                          z-index: 1;
                          position: sticky;
                        ">
                                            Transaction Id
                                        </th>
                                        <th scope="col" style="
                          text-align: center;
                          top: 0;
                          background-color: #ff8084;
                          color: white;
                          z-index: 1;
                          position: sticky;
                        ">
                                            Payment Type
                                        </th>
                                        <th scope="col" style="
                          text-align: center;
                          top: 0;
                          background-color: #ff8084;
                          color: white;
                          z-index: 1;
                          position: sticky;
                        ">
                                            Delivery Store
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="align-content: center; text-align: center">
                                            {{ OrderDetails.pti }}
                                        </td>
                                        <td style="align-content: center; text-align: center">
                                            {{ OrderDetails.pti }}
                                        </td>
                                        <td style="align-content: center; text-align: center">
                                            <span *ngIf="OrderDetails.storeName == null" style="color: red">No Store
                                                Selected</span>
                                            <span *ngIf="OrderDetails.storeName != null" style="color: green">{{
                                                OrderDetails.storeName }}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <hr style="opacity: 1.08; color: #dee2e6" />
            </div>
        </div>
    </div>
</ng-template>

<!-- Custom Modal -->
<ng-template #customModal let-c="close" let-d="dismiss">
    <div class="modal-header" style="padding: 5px">
        <div class="row" style="display: contents">
            <div class="col">
                <h4 class="modal-title" style="margin-top: 2%">Product Image</h4>
            </div>
            <div class="col" style="text-align: end">
                <button id="closebtnhaa" style="border-radius: 100px; color: white; padding: 1px" type="button"
                    aria-label="Close" class="close btn btn-primary btn-sm m-1"
                    (click)="d('Cross click'); removeBlur()">
                    <i class="fa fa-times" aria-hidden="true" style="
                color: #ffff;
                font-size: large;
                margin-top: 4px;
                margin-bottom: 4px;
                margin-left: 6px;
                margin-right: 6px;
              "></i>
                </button>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <img [src]="img" style="height: 700px; width: 765px" />
    </div>
</ng-template>





<div style="display: none;    z-index: 999;
      position: fixed;" id="reportload">
  <div class="bouncing-loader">
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>