import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../auth.service';
import { Router } from '@angular/router';
import { MyService } from 'src/app/myservice';
import { NavService } from 'src/app/shared/service/nav.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pinscreen',
  templateUrl: './pinscreen.component.html',
  styleUrls: ['./pinscreen.component.scss']
})
export class PinscreenComponent implements OnInit {

  public pinScreen: UntypedFormGroup;

  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private menuService: NavService,
    private myService: MyService,
    private toastr: ToastrService
  ) {
    this.pinScreen = this.fb.group({
      pin: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^[0-9]+$')]]
    });

    // console.log(this.menuService.MENUITEMS)
    this.roleConfig = this.menuService.MENUITEMS
    // this.menuService.MENUITEMS.subscribe(menuData => {
    //   this.roleConfig = menuData;
    //   // console.log('Menu Data:', this.roleConfig);
    // });
  }

  ngOnInit(): void {

  }

  Usersetdata: any = []
  Getuserdata: any = []
  roleConfig: any = [];
  verifyPin(): void {
    if (this.pinScreen.valid) {
     document.getElementById('logload').style.display = 'block'
      const pin = this.pinScreen.get('pin')?.value;
      const accountid = sessionStorage.getItem('accId');
      this.authService.verifyPin(pin, accountid).subscribe((data: any) => {
        sessionStorage.setItem('User', JSON.stringify(data.user));

       
        // console.log(data)
        this.myService.UserSetting.subscribe(datax => {
          // console.log(datax)
        })
        if (data.status === 200) {
          document.getElementById('logload').style.display = 'none'
          this.myService.User.next(data.user)
          const dataparameter = {
            userid: data.user.id,
            usersetid: data.user.usersettingid
          }
          // console.log(dataparameter)
          sessionStorage.setItem('userSettindata', JSON.stringify(dataparameter));

          const companyId = data.user.companyId;
          sessionStorage.setItem('companyId', companyId);

          this.authService.Usersetting(dataparameter.usersetid, dataparameter.userid).subscribe((datay: any) => {
            // console.log(datay)
            this.Usersetdata = datay['data'];
            // console.log(this.Usersetdata)
            this.Getuserdata = this.Usersetdata.ppl
            // console.log(this.Getuserdata)
            this.navigateBasedOnPermissions();
           // document.getElementById('logload').style.display = 'none';
          })
          //  document.getElementById('logload').style.display = 'none'
          // console.log(this.Getuserdata)      
        } else {
          // alert('Invalid PIN');
            document.getElementById('logload').style.display = 'none'
          this.toastr.error('Error', 'Invalid PIN')   
          }
      });
    }
    else{
           document.getElementById('logload').style.display = 'none'
        this.toastr.error('Error', 'Invalid PIN')   

    }
  }
  showPin = false; // Default state

  togglePinVisibility() {
    this.showPin = !this.showPin;
  }
  
  preventNonNumeric(event: KeyboardEvent) {
    if (event.key < '0' || event.key > '9') event.preventDefault();
  }


  navigateBasedOnPermissions(): void {
    const accessiblePages = this.getAccessiblePages(this.roleConfig);
    // console.log(accessiblePages)

    const firstAccessiblePage = accessiblePages.find((page: any) =>
      this.Getuserdata.includes(page.pageid)
    );

    if (firstAccessiblePage) {
      // console.log(firstAccessiblePage)
      this.router.navigateByUrl(firstAccessiblePage.path);
    } else {
      alert('No accessible pages found for this user.');
    }
  }


  private getAccessiblePages(menu: any[]): any[] {
    // console.log(menu)
    let pages: any[] = [];

    menu.forEach(item => {
      if (item.children) {
        pages = pages.concat(this.getAccessiblePages(item.children));
      } else if (item.pageid !== undefined) {
        pages.push(item);
      }
    });
    return pages;
  }

}
