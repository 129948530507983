import { DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TableService } from 'src/app/shared/service/table.service';
import { AuthService } from '../auth.service';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
// import { content } from 'src/app/shared/routes/content-routes';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss'],
  providers: [TableService, DecimalPipe]
})
export class CustomerListComponent implements OnInit {

  constructor(private auth: AuthService, private modalService: NgbModal, private toastr: ToastrService) {

  }

  ngOnInit(): void {
    // throw new Error('Method not implemented.');
    this.getcustomer()
  }
  cphone:string = "";
  cname:string = "";
  isCollapsed = true;
  // Customer Index
  Customer: any = []
  records: number = 100


  fromDateStr: string = "";
  toDateStr: string = "";

  getcustomer() {
    document.getElementById('preloadercuslist').style.display = 'block'
    this.auth.GetCustomers(12, this.fromDateStr, this.toDateStr, this.records,this.cname,this.cphone).subscribe(data => {
      document.getElementById('preloadercuslist').style.display = 'none'
      console.log(data)
      this.Customer = data["data"]

      this.Customer.forEach((element, i) => {
        element.Sno = i + 1
      });

      this.FilteredCustomer = this.Customer
      console.log(this.Customer);
      this.collectionSize = this.Customer.length

      this.refreshpagination()

    })
  }

  selectrecors(data) {
    this.records = data
  }
  // Table Pagination
  FilteredCustomer: any = []

  collectionSize: any
  page = 1;
  pageSize = 10;
  EcomCustomerData :any = []
  //refreshpagination() {
    // this.FilteredCustomer = this.Customer.map((country, i) => ({ id: i + 1, ...country }))
    //   .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    // console.log(this.FilteredCustomer);
  //}
  refreshpagination() {
    this.EcomCustomerData = this.FilteredCustomer.map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    // console.log(this.EcomProductsdata);
  }

  // Search Customer

  SearchValues: string = "";
  filteredcus: any;
  searchTable() {

    const searchValue = this.SearchValues.toLowerCase();
    // console.log(searchValue)
    this.FilteredCustomer = this.Customer.filter((Cus: any) => {
      const Name =
      Cus.name && Cus.name.toLowerCase().includes(searchValue);
    const PhoneNo =
      Cus.phoneNo &&
      Cus.phoneNo.toLowerCase().includes(searchValue);
    const Id =
      Cus.id &&
      Cus.id.toString().includes(searchValue);

    return Name || PhoneNo || Id 
    });


    // const searchValue = this.SearchValues.toLowerCase();
    // const dataToSearch =
    //   this.filteredcus != null ? this.filteredcus : this.Customer;
    // this.FilteredCustomer = dataToSearch.filter((Cus: any) => {
    //   const Name =
    //     Cus.name && Cus.name.toLowerCase().includes(searchValue);
    //   const PhoneNo =
    //     Cus.phoneNo &&
    //     Cus.phoneNo.toLowerCase().includes(searchValue);
    //   const Id =
    //     Cus.id &&
    //     Cus.id.toString().includes(searchValue);

    //   return Name || PhoneNo || Id 
    // });

    this.page = 1;
    this.collectionSize = this.FilteredCustomer.length;
    this.refreshpagination();

  }

  // Get Customer Address
  Address: any = []
  CustName: any = ''
  isBlocked: boolean
  GetCustAddrs(content, id, name) {
    this.CustName = name
    this.auth.GetCustomersAddrs(id).subscribe(data => {
      this.Address = data["data"]
      console.log(this.Address)
      this.modalService.open(content, {
        centered: true,
        size: "xl",
      });

    })
  }

  // openImgModal(content, data): void {
  //   console.log(data)

  // }
  // Block Customer
  BlockCust: any = []
  BlockCustomer(blockcust, data) {
    console.log(data)
    this.BlockCust = data
    this.CustName = data.Name
    this.isBlocked = data.isBlocked
    this.modalService.open(blockcust, {
      centered: true,
      size: "l",
    });


  }
  saveBlockCustomer(isblocked) {
    console.log(isblocked)
    const Id = this.BlockCust.id
    const CompanyId = this.BlockCust.companyId
    const isBlocked = isblocked
    this.auth.blockCustomer(Id, CompanyId, isBlocked).subscribe((data: any) => {
      // this.Address = data["data"]
      console.log(data)
      if (data.status == 200) {
        this.toastr.success('Success', 'Data Blocked Successfull');
        this.modalService.dismissAll();
        this.getcustomer()
      } else {
        this.toastr.error('Error', 'Data Blocked UnSuccessfull');
      }

      // this.modalService.open(content, {
      //   centered: true,
      //   size: "xl",
      // });

    })
  }

  // Table Sorting
  sortOrder = true;
  sortField = "";
  sortData(field: string) {
    if (this.sortField === field) {
      this.sortOrder = !this.sortOrder;
    } else {
      this.sortOrder = true;
    }
    this.sortField = field;

    this.FilteredCustomer.sort((a, b) => {
      let valueA, valueB;

      if (field === "Id") {
        valueA = a.id;
        valueB = b.id;
      } else if (field === "Name") {
        valueA = a.name;
        valueB = b.name;
      } else if (field === "PhoneNo") {
        valueA = a.phoneNo;
        valueB = b.phoneNo;
      } else if (field === "CreatedDate") {
        valueA = new Date(a.createdDate);
        valueB = new Date(b.createdDate);
      } else if (field === "isBlocked") {
        valueA = a.isBlocked || "";
        valueB = b.isBlocked || "";
      } else if (field === "OrderCount") {
        valueA = a.orderCount;
        valueB = b.orderCount;
      }

      if (typeof valueA === "string") {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
      }

      if (valueA < valueB) {
        return this.sortOrder ? -1 : 1;
      } else if (valueA > valueB) {
        return this.sortOrder ? 1 : -1;
      } else {
        return 0;
      }
    });
  }


  // Date Select



  // DateRange-Picker

  hoveredDate: NgbDateStruct;
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;




  isHovered = (date) =>
    this.fromDate &&
    !this.toDate &&
    this.hoveredDate &&
    after(date, this.fromDate) &&
    before(date, this.hoveredDate);

  isInside = (date) => after(date, this.fromDate) && before(date, this.toDate);

  isFrom = (date) => equals(date, this.fromDate);

  isTo = (date) => equals(date, this.toDate);

  datePickerOpened: boolean = false;

  isopen = true

  openDatePicker() {

    this.isopen = false
    this.datePickerOpened = true;
    console.log("Opening date picker...");
  }

  closeDatePicker() {
    this.datePickerOpened = false;
    this.logSelectedDateRange();
  }

  selectToday() {
    const today = new Date();
    this.fromDate = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate(),
    };
    this.toDate = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate(),
    };
    this.datePickerOpened = false;
    this.logSelectedDateRange();
  }

  selectYesterday() {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    this.fromDate = {
      year: yesterday.getFullYear(),
      month: yesterday.getMonth() + 1,
      day: yesterday.getDate(),
    };
    this.toDate = {
      year: yesterday.getFullYear(),
      month: yesterday.getMonth() + 1,
      day: yesterday.getDate(),
    };
    this.datePickerOpened = false;
    this.logSelectedDateRange();
  }

  selectLast7Days() {
    const today = new Date();
    const last7Days = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    this.fromDate = {
      year: last7Days.getFullYear(),
      month: last7Days.getMonth() + 1,
      day: last7Days.getDate(),
    };
    this.toDate = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate(),
    };
    this.datePickerOpened = false;
    this.logSelectedDateRange();
  }

  onDateChange(date: NgbDateStruct | null) {
    console.log(date)
    if (date) {
      if (!this.fromDate) {
        this.fromDate = date;
      } else if (!this.toDate && after(date, this.fromDate)) {
        this.toDate = date;
        this.datePickerOpened = false; // Close the date picker after selecting the range
        this.logSelectedDateRange(); // Log the selected date range
      } else {
        this.toDate = null;
        this.fromDate = null;

      }
    }
  }

  setFromDateToDate(date: NgbDateStruct) {
    this.fromDate = date;
    this.toDate = date;
    this.datePickerOpened = false;
    this.logSelectedDateRange();
  }


  logSelectedDateRange() {
    if (this.fromDate && this.toDate) {
      console.log(
        "Selected Date Range:",
        this.formatDate(this.fromDate),
        "to",
        this.formatDate(this.toDate)
      );
      const fromDateStr = this.formatDate(this.fromDate);
      const toDateStr = this.formatDate(this.toDate);
      console.log(fromDateStr, toDateStr)
      this.fromDateStr = fromDateStr
      this.toDateStr = toDateStr
      // this.dateRangeSelected.emit({ fromDateStr, toDateStr });
    }
  }

  formatDate(date: NgbDateStruct) {
    return `${date.year}-${date.month}-${date.day}`;
  }

  clearval() {
    this.fromDate = null
    this.toDate = null
    this.datePickerOpened = false
    this.isopen = true
    this.fromDateStr = ''
    this.toDateStr = ''

  }


  Resetadv() {
    this.fromDate = null
    this.toDate = null
    this.fromDateStr = '',
    this.cname = ''
    this.cphone = ''
    this.toDateStr = '',
    this.records = 100
    this.getcustomer()
  }
}



// DateRange-Picker

const equals = (one: NgbDateStruct, two: NgbDateStruct) =>
  one &&
  two &&
  two.year === one.year &&
  two.month === one.month &&
  two.day === one.day;

const before = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
      ? one.month === two.month
        ? one.day === two.day
          ? false
          : one.day < two.day
        : one.month < two.month
      : one.year < two.year;

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
  !one || !two
    ? false
    : one.year === two.year
      ? one.month === two.month
        ? one.day === two.day
          ? false
          : one.day > two.day
        : one.month > two.month
      : one.year > two.year;
