import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgChartsModule } from 'ng2-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartistModule } from 'ng-chartist'
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerListComponent } from './customer-list.component';
import { CustomerListRoutingModule } from './customer-list-routing.module';

@NgModule({
    declarations: [CustomerListComponent],
    imports: [
        CommonModule,
        NgChartsModule,
        Ng2GoogleChartsModule,
        NgxChartsModule,
        ChartistModule,
        CustomerListRoutingModule,
        SharedModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class CustomerModule { }
