import { Routes } from '@angular/router';
import { AuthGuardService } from 'src/app/authgurd.service';

export const content: Routes = [

  {
    path: 'ecomdash',
    canActivate: [AuthGuardService],
    data: { title: 'ecomdash App', pageid: 1 },
    loadChildren: () => import('../../components/pages/ecom-dash-board/ecom-dash-board.module').then(m => m.EcomDashBoardModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuardService],
    data: { title: 'Dashboard', pageid: 2 },
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },

  {
    path: 'reports',
    canActivate: [AuthGuardService],
    data: { title: 'Order Reports', pageid: 3 },
    loadChildren: () => import('../../components/reports/reports.module').then(m => m.ReportsModule),
  },

  {
    path: 'transwise-report',
    canActivate: [AuthGuardService],
    data: { title: 'Transwise Reports', pageid: 13 },
    loadChildren: () => import('../../components/transwise-report/transwise-report.module').then(m => m.transwiseModule),
  },

  
  {
    path: 'companywise-report',
    canActivate: [AuthGuardService],
    data: { title: 'Companywise Reports', pageid: 15 },
    loadChildren: () => import('../../components/companywise-report/companywise-report.module').then(m => m.companywiseModule),
  },



  {
    path: 'customer-list',
    canActivate: [AuthGuardService],
    data: { title: 'CustomerList', pageid: 4 },
    loadChildren: () => import('../../components/customer-list/customer-list.module').then(m => m.CustomerModule),
  },
  {
    path: 'order-enquiry',
    canActivate: [AuthGuardService],
    data: { title: 'Order Enquiry', pageid: 5 },
    loadChildren: () => import('../../components/order-enquiry/order-enquiry.module').then(m => m.OrderEnquiryModule),
  },

  {
    path: 'product-report',
    canActivate: [AuthGuardService],
    data: { title: 'Product Report', pageid: 6 },
    loadChildren: () => import('../../components/productreport/productreport.module').then(m => m.ProductreportModule),
  },

  {
    path: 'Addproductecom',
    canActivate: [AuthGuardService],
    data: { title: 'Addproductecom', pageid: 7 },
    loadChildren: () => import('../../components/addproduct-ecom/addproduct-ecom.module').then(m => m.AddproductEcomModule),

  },

  {
    path: 'Categoryecom',
    canActivate: [AuthGuardService],
    loadChildren: () => import('../../components/category-ecom/category-ecom.module').then(m => m.CategoryEcomModule),
    data: { title: 'Categoryecom', pageid: 8 },

  },

  {
    path: 'Addons',
    canActivate: [AuthGuardService],
    loadChildren: () => import('../../components/addons-ecom/addons-ecom.module').then(m => m.AddonsEcomEcomModule),
    data: { title: 'Addons', pageid: 9 },
  },

  {
    path: 'Coupons',
    canActivate: [AuthGuardService],
    loadChildren: () => import('../../components/coupons-ecom/coupons-ecom.module').then(m => m.CouponsEcomModule),
    data: { title: 'Coupons', pageid: 10 },
  },

  {
    path: 'Referral',
    canActivate: [AuthGuardService],
    loadChildren: () => import('../../components/referral/referral.module').then(m => m.ReferralModule),
    data: { title: 'Coupons', pageid: 16 },
  },


  {
    path: 'settings',
    canActivate: [AuthGuardService],
    loadChildren: () => import('../../components/setting/setting.module').then(m => m.SettingModule),
    data: { title: 'Coupons', pageid: 11 },
  },



  {
    path: 'storeinformations',
    canActivate: [AuthGuardService],
    loadChildren: () => import('../../components/storeinformations/storeinformations.module').then(m => m.StoreInformationsModule),
    data: { title: 'storeinformations', pageid: 12 },
  },

  {
    path: 'products',
    canActivate: [AuthGuardService],
    loadChildren: () => import('../../components/products/products.module').then(m => m.ProductsModule),
    data: {
      breadcrumb: "Products"
    }
  },


  {
    path: 'pages',
    canActivate: [AuthGuardService],
    loadChildren: () => import('../../components/pages/pages.module').then(m => m.PagesModule),
    data: {
      breadcrumb: "Pages"
    }
  },
  {
    path: 'usersetting',
    canActivate: [AuthGuardService],
    data: { title: 'Addproductecom', pageid: 14 },
    loadChildren: () => import('../../components/usersetting/usersetting.module').then(m => m.UsersettingModule),

  },














];