import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { threadId } from 'worker_threads';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  localUrl = 'https://localhost:7095/api/'
  EcomLiveUrl = 'https://uat-ecom.azurewebsites.net/api/'
  // localURL = 'https://uat-bizpos.azurewebsites.net/api/' //home
  Live_Url = 'https://uat-retailpos.azurewebsites.net/api/' //login

  constructor(private http: HttpClient) { }

  public isloading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  //Login
  Login(email: any, password: any) {
    return this.http.get(this.EcomLiveUrl + 'ECom/retail_sn?email=' + email + '&password=' + password)
  }

  // Pin Verification
  verifyPin(pin: any, accountid: any) {
    return this.http.get(this.EcomLiveUrl + 'ECom/retail_Epin?pin=' + pin + '&accountid=' + accountid,)
  }
  Usersetting(usid: any, userid: any) {
    return this.http.get(this.EcomLiveUrl + 'ECom/retail_getpage?usid=' + usid + '&userid=' + userid)
  }

  //-----------------------------------------------------------dashboard--------------------------------------------------------------------------------------

  Dashboard_index(companyId: number) {
    return this.http.get(this.EcomLiveUrl + 'ECom/Dashboard_index?companyId=' + companyId)
  }
  Dashboard_Orders() {
    return this.http.get(this.EcomLiveUrl + 'ECom/Dashboard_Orders')

  }



  //--------------------------------------------------ecom order dashboard-------------------------------------------------------------------------------
  // GetEcomOrderIndexN(companyId:number,orderId :number,storeId :number,statusid:number,orderDate:String,deliveryDate:string)
  // {
  //   return this.http.get(this.localUrl +'ECom/GetEcomOrderIndex?companyId='+companyId+ '&orderId='+orderId+'&storeId='+storeId
  //     +'&statusid='+statusid+'&orderDate='+orderDate+'&deliveryDate='+deliveryDate
  //   )
  // }

  GetEcomOrderIndexN(companyId: number) {
    return this.http.get(this.EcomLiveUrl + 'ECom/GetEcomOrderIndex?companyId=' + companyId)
  }

  GetDetailById_Dashboard(orderId) {
    return this.http.get(this.EcomLiveUrl + 'ECom/GetDetailById_Dashboard?orderId=' + orderId)
  }
  getCustIndex(custid) {
    return this.http.get(this.EcomLiveUrl + 'ECom/getCustIndex?custid=' + custid)
  }

  payment_link(payload: any) {
    return this.http.post(this.EcomLiveUrl + 'ECom/Payment_Admin', payload);
  }
  DashboardCancelIndex(companyId: number, ordeDate: string) {
    return this.http.get(this.EcomLiveUrl + 'ECom/DashboardCancelIndex?companyId=' + companyId + '&orderDate=' + ordeDate)
  }


  GetAllStores() {
    return this.http.get(this.EcomLiveUrl + 'ECom/getallStores')
  }
  getallCompany() {
    return this.http.get(this.EcomLiveUrl + 'Ecom/getallCompany')
  }
  GetLiveEcomOrders() {
    return this.http.get(this.EcomLiveUrl + 'ECom/GetEcomOrders')
  }

  RevertStatus(orderId, statusId, posrorderId) {
    return this.http.get(this.EcomLiveUrl + 'ECom/RevertStatusUpd?ecomorderId=' + orderId + '&statusId=' + statusId + '&posrorderId=' + posrorderId)
  }

  // Status Change  
  Getorderstatus(payload) {
    return this.http.post(this.EcomLiveUrl + 'ECom/OrderStatus', payload);
  }
  //save map store
  SaveEcomOrder(payload: any) {
    return this.http.post(this.EcomLiveUrl + 'ECom/SaveEcomOrder', payload);
  }


  //edit 
  GetCustAddress(custId) {
    // console.log(custId)
    return this.http.get(this.EcomLiveUrl + 'ECom/getCustAddress2?custid=' + custId)
  }
  //save editorder
  EditCustomerData(payload) {
    return this.http.post(this.EcomLiveUrl + 'ECom/editCustAddress', payload);
  }
  //edit order message
  // SaveBDAYMSG(otid: string, oid: string, msg: string) {
  //   const url = `${this.EcomLiveUrl}ECom/edtbdmsg`;
  //   const body = { otid, oid, msg };
  //   return this.http.post(url, body);
  // }
  // Edit Ordernotes
  EditOrdNote(oid: number, note: any, ecomId: number) {
    const url = `${this.EcomLiveUrl}ECom/edtodnote?oid=${oid}&note=${note}&ecomId=${ecomId}`;
    return this.http.post(url, {});
  }
  //edit order message
  // EditOrdMsg(otid: number, oid: number, msg: string) {
  //   const url = `${this.EcomLiveUrl}ECom/edtbdmsg?otid=${otid}&oid=${oid}&msg=${msg}`;
  //   return this.http.post(url, {});
  // }
  EditOrdMsg(pid: number, oid: number, msg: string, ecomId) {
    return this.http.get(this.EcomLiveUrl + 'ECom/edtbdmsg?pid=' + pid + '&oid=' + oid + '&msg=' + msg + '&ecomId=' + ecomId)

  }
  // Edit Delivery Date
  EditDelDate(oid: number, ddate: string) {
    const url = `${this.EcomLiveUrl}ECom/edtDelDate?oid=${oid}&ddate=${ddate}`;
    return this.http.post(url, {});
  }


  //---------------------------------------------Order Report -----------------------------------------------------------------------------------------------------

  //index
  GetOrderReports(payload: any) {
    // console.log(payload)
    return this.http.post(this.EcomLiveUrl + 'ECom/OrderReport_Index', payload);
  }
  GetTransReports(orderId: any, storeId: number, fromDate: string, toDate: string, paymentType: number) {
    return this.http.get(this.EcomLiveUrl + 'ECom/Transwise_Report?orderId=' + orderId + '&storeId=' + storeId + '&fromDate=' + fromDate + '&toDate=' + toDate + '&paymentType=' + paymentType)
  }
  getCompanyReport(companyId: number, storeId: any, fromDate: string, toDate: string, paymentType: number) {
    return this.http.get(this.EcomLiveUrl + 'Ecom/Ecom_companyWise_Report?companyId=' + companyId + '&storeIds=' + storeId + '&fromDate=' + fromDate + '&toDate=' + toDate +
      '&paymentType=' + paymentType
    )
  }


  //------------------------------------------customer list -------------------s----------------------------------------------------------------------------

  GetCustomers(cid, fDate, TDate, record, cname, cphone) {
    return this.http.get(this.EcomLiveUrl + 'ECom/getEcomCustomer?cid=' + cid + '&fDate=' + fDate + '&TDate=' + TDate + '&record=' + record + '&cname=' + cname + '&cphone=' + cphone)
  }

  // GetCustAddress

  GetCustomersAddrs(id) {
    return this.http.get(this.EcomLiveUrl + 'ECom/getEcomCustDet?caid=' + id)
  }

  // Block Customer

  blockCustomer(id, companyId, isBlocked) {
    return this.http.get(this.EcomLiveUrl + 'ECom/Block_Customer?id=' + id + '&companyId=' + companyId + '&isBlocked=' + isBlocked)
  }

  ////////////////////////////////order enquiry////////////////////////////////////////////////////////////////////////////////////////////////

  GetProducts(companyId: number) {
    return this.http.get(this.EcomLiveUrl + 'ECom/getproducts?companyId=' + companyId)
  }

  GettOrdReviews(payload) {
    return this.http.post(this.EcomLiveUrl + 'ECom/getEcomreviews', payload)
  }

  GettOrdDetails(id) {
    // console.log(id)
    return this.http.get(this.EcomLiveUrl + 'ECom/GetOrderDetails?OrderId=' + id)
  }

  GetReviewProduct(companyId) {
    return this.http.get(this.EcomLiveUrl + 'ECom/getReveiwProducts?companyId=' + companyId)
  }










  // GetEcomOrders(fromdate: string, todate: string, storeid: number) {
  //   return this.http.get(
  //     this.EcomLiveUrl + 'ECom/GetEcomOrders?fromdate=' + fromdate + '&todate=' + todate + '&storeid=' + storeid
  //   );
  // }


  public exportAsExcelFile(json: any[], excelFileName: string): void {
    // console.log(json);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data1': worksheet }, SheetNames: ['data1'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    this.saveAsExcelFile(excelBuffer, excelFileName);
  }


  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });

    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }




  // Get Customers

  //  GetCustomers() {
  //   return this.http.get(this.EcomLiveUrl + 'Ecom/getEcomCust?cid=12')
  // }

  //  // GetCustAddress

  //  GetCustomersAddrs(id) {
  //   return this.http.get(this.EcomLiveUrl + 'Ecom/getEcomCustDet?caid=' + id)
  // }

  // // Block Customer

  // blockCustomer(id, companyId) {
  //   return this.http.get(this.EcomLiveUrl + 'Ecom/blockCustomer?id=' + id + '&companyId=' + companyId)
  // }


  //-------------------------- Get Order Enquiry Reviews,Contact------------------------------------------------------------------------------

  // GettOrdReviews(fdate, tdate, pid, star, tid) {
  //   return this.http.get(this.EcomLiveUrl + 'Ecom/getEcomreviews?fdate=' + fdate + '&tdate=' + tdate + '&pid=' + pid + '&star=' + star + '&tid=' + tid)
  // }

  // GetReviewProduct() {
  //   return this.http.get(this.EcomLiveUrl + 'Ecom/getrevieproducts')
  // }



  //-----------------------------------------------------------------product report ------------------------------------------------------------------

  GetProductReports(payload) {
    return this.http.post(this.EcomLiveUrl + 'ECom/ProductReport', payload);
  }



  //------------------------------------------------------menu management -----------------------------------------------------------------------------
  //*********************************************product**********************************************************************************************
  // //produt get

  public Categories

  public Getcategories(companyId) {
    const apiUrl = this.EcomLiveUrl + `ECom/Get_Category_tax?companyId=` + companyId
    this.Categories = this.http.get(apiUrl);
    return this.Categories;
  }

  public ecomProducts

  public GetEcomproductdata(cid) {
    const apiUrl = this.EcomLiveUrl + `ECom/getProductlistEcom?cid=` + cid
    this.ecomProducts = this.http.get(apiUrl);
    return this.ecomProducts;
  }

  //product get
  public ecomProdata
  public GetEcomdata(cid) {
    const apiUrl = this.EcomLiveUrl + 'ECom/getecomPd?companyId=' + cid
    this.ecomProdata = this.http.get(apiUrl);
    return this.ecomProdata;
  }

  // Update product isActive----------------------------------------------------------------------------------------------
  public UpdateActiveprod
  public updateActiveprod(id) {
    const apiUrl = this.EcomLiveUrl + `ECom/isactive?pid=${id}`;
    this.UpdateActiveprod = this.http.post(apiUrl, id);
    return this.UpdateActiveprod;
  }

  public get _getUpdateactprod(): Observable<AuthService[]> {
    return this.UpdateActiveprod;
  }

  public get _geteditItem(): Observable<AuthService[]> {
    return this.ecomEditProducts;
  }

  // Product edit
  public ecomEditProducts

  public GetEcomproductEdit(pid) {
    const apiUrl = this.EcomLiveUrl + `ECom/getProductEcom?pid=` + pid
    this.ecomEditProducts = this.http.get(apiUrl);
    return this.ecomEditProducts;
  }

  // Product save
  public AddproductEcom
  Addproductecom(employeeData: any, empImgFile: File) {
    const formData: FormData = new FormData();

    for (let key in employeeData) {
      formData.append(key, employeeData[key]);
    }

    if (empImgFile) {
      formData.append('image', empImgFile, empImgFile.name);
    }
    const apiUrl = this.EcomLiveUrl + `ECom/AddProductEcom`;
    this.UpdateproductEcom = this.http.post(apiUrl, formData);
    return this.UpdateproductEcom;
  }

  toFormData(formValue) {
    // console.log(formValue)
    const formData = new FormData();

    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      // console.log(value)
      formData.append(key, value);
    }
    return formData;
  }

  // Product Update 
  public UpdateproductEcom
  Updateproductecom(employeeData: any, empImgFile: File) {
    const formData: FormData = new FormData();

    for (let key in employeeData) {
      formData.append(key, employeeData[key]);
    }

    if (empImgFile) {
      formData.append('image', empImgFile, empImgFile.name);
    }

    // return this.http.post(`${this.base_Url_4}hypertech/SaveEmployee`, formData);
    const apiUrl = this.EcomLiveUrl + `ECom/updateProductEcom`;
    this.UpdateproductEcom = this.http.post(apiUrl, formData);
    return this.UpdateproductEcom;
  }

  //**************************************************************************category*******************************************************************

  // get-category
  public ecomCatData
  public GetEcomCatdata() {
    const apiUrl = this.EcomLiveUrl + `ECom/getcatelistEcom?parid=117`
    this.ecomCatData = this.http.get(apiUrl);
    return this.ecomCatData;
  }

  // Add-Category------------------------------------------------------------------------------------------------------------

  public AddCategoryEcom
  public Addcategoryecom(body) {
    //  console.log(body)
    const apiUrl = this.EcomLiveUrl + `ECom/insertcatelistEcom`;
    this.AddCategoryEcom = this.http.post(apiUrl, body);
    return this.AddCategoryEcom;

  }
  //update
  public EditCategoryEcom
  public editcategoryecom(body, id) {
    //  console.log(body)
    const apiUrl = this.EcomLiveUrl + `ECom/insertcatelistEcom?cateid=` + id;
    this.EditCategoryEcom = this.http.post(apiUrl, body);
    return this.EditCategoryEcom;

  }

  // Update Category isActive---------------------------------------------------------------------------------------------------
  public UpdateActivecat
  public updateActivecat(id) {
    const apiUrl = this.EcomLiveUrl + `ECom/isactive?cid=${id}`;
    this.UpdateActivecat = this.http.post(apiUrl, id);
    return this.UpdateActivecat;

  }


  //****************************************************************addons********************************************************************************

  // Get Addons-------------------------------------------------------------------------------------------------------------------------------------------
  public ecomAddonsData
  public GetEcomAddonsdata() {
    const apiUrl = this.EcomLiveUrl + `ECom/AddonslistEcom`
    this.ecomAddonsData = this.http.get(apiUrl);
    return this.ecomAddonsData;
  }


  // Add Addons--------------------------------------------------------------------------------------------------------------------------------------------
  public AddAddonsEcom
  public Addaddonsecom(body) {
    // console.log(body)
    const apiUrl = this.EcomLiveUrl + `ECom/insertAddonslistEcom`;
    this.AddAddonsEcom = this.http.post(apiUrl, body);
    return this.AddAddonsEcom;

  }

  // Update Addons---------------------------------------------------------------------------------------------------------------
  public UpdateAddonsEcom
  public updateaddonsecom(body, id) {
    // console.log(body)
    const apiUrl = this.EcomLiveUrl + `ECom/insertAddonslistEcom?addonid=` + id;
    this.UpdateAddonsEcom = this.http.post(apiUrl, body);
    return this.UpdateAddonsEcom;

  }

  // Update addon isactive----------------------------------------------------------------------------------------------------------
  public UpdateActiveAddon
  public updateActiveAddon(id) {
    const apiUrl = this.EcomLiveUrl + `ECom/isactive?aid=${id}`;
    this.UpdateActiveAddon = this.http.post(apiUrl, id);
    return this.UpdateActiveAddon;

  }
  //not used
  GetStores(CompanyId: number) {
    var formURL = this.EcomLiveUrl + 'ECom/GetEcomStores?CompanyId=' + CompanyId;
    return this.http.get(formURL);
  }

  // GetReceipt_WO(orderid: number) {
  //   return this.http.get(
  //     this.EcomLiveUrl + 'POSOrder/GetReceipt_WO?orderid=' + orderid
  //   );
  // }

  // Get coupon list-----------------------------------------------------------------------------------------------------------------

  public couponlist

  public Getcouponlist(cid) {
    const apiUrl = this.EcomLiveUrl + `ECom/getHcoupenlist?compid=` + cid
    this.couponlist = this.http.get(apiUrl);
    return this.couponlist;
  }

  //*********************************************************************Coupon******************************************************************* */

  // edit coupon------------------------------------------------------------

  public couponedit

  public Couponedit(id) {
    const apiUrl = this.EcomLiveUrl + `ECom/getHcoupenlistdetail?coopsouid=` + id
    this.couponedit = this.http.get(apiUrl);
    return this.couponedit;
  }



  // save coupon-------------------------------------------------------------------------------------------------
  public couponsave
  public SaveCoupon(body) {
    const apiUrl = `https://uat-ecom.azurewebsites.net/api/Ecom/applyCoupenEcom`
    this.couponsave = this.http.post(apiUrl, body);
    return this.couponsave;
  }

  // Update-coupon-------------------------------------------------------------------------------------------------
  public couponupdate
  public UpdateCoupon(body) {
    const apiUrl = this.EcomLiveUrl + `ECom/updateCoupenEcom`
    this.couponupdate = this.http.post(apiUrl, body);
    return this.couponupdate;
  }


  // coupon cat-------------------------------------------------
  public ecomCat
  public GetEcomCat(id) {
    const apiUrl = `https://uat-ecom.azurewebsites.net/api/Ecom/getcopsuport?cid=` + 12 + '&tid=' + id
    this.ecomCat = this.http.get(apiUrl);
    return this.ecomCat;
  }

  // Update COUPON isActive---------------------------------------------------------------------------------------------------

  public UpdateActivecoupon
  public updateActiveCou(id) {
    const apiUrl = this.EcomLiveUrl + `ECom/isactive?couid=${id}`;
    this.UpdateActivecoupon = this.http.post(apiUrl, id);
    return this.UpdateActivecoupon;
  }

  public get _getUpdateactcou(): Observable<AuthService[]> {
    return this.UpdateActivecoupon;
  }
  // coupon cat-------------------------------------------------
  public ecomProd

  public GetEcomProd(id) {
    const apiUrl = `https://uat-ecom.azurewebsites.net/api/Ecom/getcopsuport?cid=` + 12 + '&tid=' + id
    this.ecomProd = this.http.get(apiUrl);
    return this.ecomProd;
  }


  ///////////////////////////////////////////////////////////////settings page///////////////////////////////////////////////////////////////////////////////////////////

  //settings api
  uploadImage(image: File) {
    const formData = new FormData();
    formData.append('image', image);

    return this.http.post(this.EcomLiveUrl + 'ECom/Image_Post_Method', formData);
  }

  GetBannerImage(companyId: number) {
    return this.http.get(this.EcomLiveUrl + 'ECom/GetIndex_settings?companyId=' + companyId)
  }



  Save_EcomSource(payload: any, Image: File) {
    const formData: FormData = new FormData();

    for (let key in payload) {
      formData.append(key, payload[key]);
    }

    if (Image) {
      formData.append('image', Image, Image.name);
    }
    return this.http.post(this.EcomLiveUrl + 'ECom/ImageSave_Post', formData);
  }


  //setting page footerseting
  FooterSetting_Get(companyId: number) {
    return this.http.get(this.EcomLiveUrl + 'ECom/FooterSetting_Get?companyId=' + companyId)
  }

  SaveStoreInfo(payload: any, Image: File) {
    const formData: FormData = new FormData();

    for (let key in payload) {
      formData.append(key, payload[key]);
    }

    if (Image) {
      formData.append('image', Image, Image.name);
    }
    return this.http.post(this.EcomLiveUrl + 'ECom/SaveStoreInfo', formData);
  }
  GetEcoOrderSamp(custId) {
    return this.http.get(this.EcomLiveUrl + 'Home/getEcomOrdersEdit?ecomorderid=' + custId)
  }

  public updateuser
  public UpdateUser(body) {
    const apiUrl = this.EcomLiveUrl + 'ECom/US_Update'
    this.updateuser = this.http.post(apiUrl, body);
    return this.updateuser;
  }

  //user setting page
  public settinguser
  public usersetting(cid) {
    // const apiUrl =this.localUrl+ ECom/US_GetUser?=companyId + cid;
    const apiUrl = this.EcomLiveUrl + 'ECom/US_GetUser?companyId=' + cid

    // this.settinguser = this.http.post(apiUrl, 12);
    this.settinguser = this.http.get(apiUrl);

    return this.settinguser;

  }
  getCategory(companyId: number) {
    return this.http.get(this.EcomLiveUrl + 'ECom/getCategories?ecomorderid=' + companyId)
  }



  public GenerateRefCode2(payload) {
    const apiUrl = this.localUrl + `ECom/GenerateRefCode2`
    return this.http.post(apiUrl, payload);

  }


  //Update referral//

  public UpdateRefCode2(payload) {
    const apiUrl = this.localUrl + `ECom / UpdateReferal2`
    return this.http.post(apiUrl, payload);

  }

  //index referral//

  public referralcode

  public GetRefCoupon2() {
    const apiUrl = this.localUrl + `ECom/GetRefCoupon2_3 `
    this.referralcode = this.http.get(apiUrl);
    return this.referralcode;
  }

}
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';




