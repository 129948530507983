import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { MyService } from './myservice';
import { AuthService } from './components/auth.service';
import { content } from './shared/routes/content-routes';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private myService: MyService,
    private authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object // Inject platformId
  ) { }

  Usersetdata: any;

  private checkUserLogin(route: ActivatedRouteSnapshot): Observable<boolean> {
    // Check if the environment is the browser
    if (!isPlatformBrowser(this.platformId)) {
      return of(false); // Deny access if not in browser
    }

    // Now safe to use sessionStorage
    const logState = sessionStorage.getItem('logState');
    if (logState !== 'logged_in') {
      this.router.navigate(['/auth/login']);
      return of(false);
    }

    const userSettindata = JSON.parse(sessionStorage.getItem('userSettindata') || '{}');
    if (!userSettindata.usersetid || !userSettindata.userid) {
      this.router.navigate(['/auth/login']);
      return of(false);
    }

    const usid = userSettindata.usersetid;
    const userid = userSettindata.userid;

   // console.log(usid, userid)

    return this.authService.Usersetting(usid, userid).pipe(
      map((data: any) => {
       // console.log(data)
        this.Usersetdata = data['data'];
       // console.log(this.Usersetdata)
        this.myService.UserSetting.next(this.Usersetdata.ppl);
      //  console.log(route)
       // console.log(content)
        const pageId = route.data['pageid'];
        //console.log(pageId)

        if (pageId && this.Usersetdata.ppl.includes(pageId)) {
          return true;
        }

        if (route.url.length > 0 && route.url[0].path === 'apps') {
          return true;
        }

        this.router.navigate(['/unauthorized']);
        return false;
      }),
      catchError(() => {
        this.router.navigate(['/unauthorized']);
        return of(false);
      })
    );
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    return this.checkUserLogin(route);
  }
}
