import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PinscreenComponent } from './pinscreen/pinscreen.component';
import { RegistrationComponent } from './registration/registration.component';


const routes: Routes = [

  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path:'login',
    component:LoginComponent,
  },

  {
    path:'pinscreen',
    component:PinscreenComponent,
  },
  {
    path:'registration',
    component:RegistrationComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
