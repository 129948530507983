import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { url } from 'inspector';
import { read } from 'fs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public active = 1;

  constructor(private auth: AuthService, private modalService: NgbModal, private toastr: ToastrService) {

  }

  ngOnInit() {
    this.GetBannerImage()
  }

  onSelect(event: any, data: any) {
    console.log(data)
    const file = event.addedFiles ? event.addedFiles[0] : null;
    console.log(file)
    if (file) {
      data.jsonData.image = file;
      data.url = URL.createObjectURL(file);
    }
    console.log(data)
  }


  SaveHomeSetting(data: any) {
    console.log(data)
    if (data.jsonData != null) {
      const payload = {
        Id: data.Id,
        jsonData: {
          name: data.jsonData.name,
          desc: data.jsonData.desc,
          f1_color: data.jsonData.f1_color,
          f2_color: data.jsonData.f2_color
        }
      }
      console.log(payload)
      const savedata = JSON.stringify(payload)
      var postdata = { payload: JSON.stringify(savedata) };
      // Send the API call
      document.getElementById('bannerload').style.display = 'block'
      this.auth.Save_EcomSource(postdata, data.jsonData.image).subscribe((response: any) => {
        console.log('Response:', response);
        if (response.status == 200) {
          this.toastr.success('Success', 'Data Saved Successfull');
          document.getElementById('bannerload').style.display = 'none'
        } else {
          this.toastr.error('Error', 'Data Saved UnSuccessfull');
        }
      });
    }
    else {
      const payload = {
        Id: data.Id,
        jsonData: {}

      }
      const savedata = JSON.stringify(payload)
      var postdata = { payload: JSON.stringify(savedata) };
      // Send the API call
      this.auth.Save_EcomSource(postdata, data.image).subscribe((response: any) => {

        console.log('Response:', response);
        if (response.status == 200) {
          document.getElementById('bannerload').style.display = 'none'
          this.toastr.success('Success', 'Data Saved Successfull');
        } else {
          this.toastr.error('Error', 'Data Saved UnSuccessfull');
        }
      });
    }


  }


  Bannerpayload: any = []
  AdvertisementPayload: any = []
  BackgroundPayload: any = []
  SmallAdvImgPayload: any = []
  GetBannerImage() {
    document.getElementById('bannerload').style.display = 'block'
    this.auth.GetBannerImage(12).subscribe((data: any) => {
      console.log(data)
      document.getElementById('bannerload').style.display = 'none'
      this.Bannerpayload = data.bannerImage.map(item => {
        return {
          Id: item.id, jsonData: JSON.parse(item.name),
          typeId: item.type,
          url: item.url
        };
      });

      this.AdvertisementPayload = data.advertisementImg.map(item => {
        return {
          Id: item.id, jsonData: JSON.parse(item.name),
          typeId: item.type,
          url: item.url
        };
      });

      this.BackgroundPayload = data.backgroundImg.map(item => {
        return {
          Id: item.id, jsonData: JSON.parse(item.name),
          typeId: item.type,
          url: item.url
        };
      });

      this.SmallAdvImgPayload = data.smallAdvImg.map(item => {
        return {
          Id: item.id, jsonData: JSON.parse(item.name),
          typeId: item.type,
          url: item.url
        };
      });
    })
    console.log(this.Bannerpayload)
  }


}
