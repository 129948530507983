import { Component, OnInit } from '@angular/core';
import * as chartData from '../../shared/data/chart';
import { doughnutData, pieData } from '../../shared/data/chart';
import { AuthService } from '../auth.service';
import * as moment from 'moment';
import { color } from 'html2canvas/dist/types/css/types/color';
import { MyService } from 'src/app/myservice';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  // pieChartData = [
  //   { name: 'A', value: 6000 },
  //   { name: 'B', value: 9000 },
  // ];
  booleanOrder: boolean = false
  boolreview: boolean = false
  role: number[] = [];

  pieChartData: any = []

  view: [number, number] = [600, 300]; // Width & height

  // Pie chart options
  showLegend = true;
  gradient = true;
  showLabels = true;
  colorScheme = { domain: ['#4CBB17', '#FF5733'] };

  onSelect(event: any) {
    // console.log('Item clicked', event);
  }


  constructor(private auth: AuthService, public myService: MyService) {
  }

  ngOnInit() {
    speechSynthesis.onvoiceschanged = () => {
      this.voices = speechSynthesis.getVoices();
    };
    this.Dashboard_indexCount()
    // this.Dashboard_Orders()

    this.myService.UserSetting.subscribe(data => {
      this.role = data;
      // console.log(this.role)
      if (!this.role.includes(3)) {
        this.booleanOrder = false
        // console.log(this.booleanOrder)
      }
      else {
        this.booleanOrder = true
      }

      if (!this.role.includes(5)) {
        this.boolreview = false
        // console.log(this.booleanOrder)
      }
      else {
        this.boolreview = true
      }

      // console.log(this.booleanOrder)
    });

    // this.Dashboard_Orders()

  }

  selectedStatus: number = 7;
  ordercount: any = []
  onlineTotal = 70;
  cashTotal = 30;
  orderIndex: any = []
  // Dashboard_Orders()
  // {
  //   // this.auth.Dashboard_Orders().subscribe((x:any)=>
  //   // {
  //   //   console.log(x)
  //   //   this.orderIndex = x['ecomorder']
  //   // })
  // }



  //ordercount
  users:number=0;
  totalOrders: number = 0;
  cancelledOrders: number = 0;
  acceptedOrders: number = 0;
  completeOrders: number = 0;
  paymentCount: any = []
  ecomReviews: any = []
  Dashboard_indexCount() {
    document.getElementById('dashorder').style.display = 'block'
    this.auth.Dashboard_index(12).subscribe((x: any) => {
      document.getElementById('dashorder').style.display = 'none'
      // console.log(x)
      this.ordercount = x['ordersCount'][0]
      this.orderIndex = x['ecomorder']
      this.users=x['customerCount'][0].customerCount
      // console.log(this.ordercount)
      this.totalOrders = this.ordercount.total_orders
      this.cancelledOrders = this.ordercount.cancelled_orders
      this.acceptedOrders = this.ordercount.accepted_orders
      this.completeOrders = this.ordercount.completed_orders
      // console.log(this.orderIndex)
      this.paymentCount = x['paymentCount']
      // console.log(this.paymentCount)
      this.ecomReviews = x['ecomReviews']
      // console.log(this.ecomReviews)


    })

    // Initialize online and cash totals


    // Iterate through the ecomReviews and calculate the totals
    this.ecomReviews.forEach(review => {
      if (review.paymentTypeId === 1) {
        this.onlineTotal += review.total;
      } else if (review.paymentTypeId === 2) {
        this.cashTotal += review.total;
      }
    });

    // Update pieChartData
    this.pieChartData.push({ name: 'Online', value: this.onlineTotal, color: '#007bff' });
    this.pieChartData.push({ name: 'Cash', value: this.cashTotal });

    console.log(this.pieChartData);


  }

  //filter index osi
  filteredosi: any;
  filteredOrders: any[] = [];
  updatedval: any = [];
  filterorder(ordstatus: number | null) {
    // console.log(ordstatus)
    // this.selectedStatus = ordstatus;
    // if (ordstatus === 7) {
    //   this.filteredosi = this.filteredOrders;
    // } else {
    //   this.filteredosi = this.filteredOrders.filter(
    //     (ord) => ord.osi === ordstatus
    //   );
    // }
    // this.updatedval = this.filteredosi;
    // this.updateOrderCounts();
  }

  voices: SpeechSynthesisVoice[] = [];
  speakText() {
    const text = "Hi Rohith, how are you?";
    const speech = new SpeechSynthesisUtterance(text);

    // Wait for voices to load
    if (this.voices.length === 0) {
      this.voices = speechSynthesis.getVoices();
    }

    // Select a female voice (Check available voices in console)
    speech.voice = this.voices.find(voice => 
      voice.name.includes('Google UK Female') || 
      voice.name.includes('Google US English') || 
      voice.name.includes('Samantha') // For Apple devices
    ) || this.voices[0]; // Fallback to first available voice

    speech.lang = "en-US";
    speech.rate = 1; // Normal speed
    window.speechSynthesis.speak(speech);
  }


}
