import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'multikart-backend';
  ngOnInit(): void {
    sessionStorage.setItem('key', 'value');
    const value = sessionStorage.getItem('key');
    const uniqueKey = 'unique_tab_id';
    const tabOpenedKey = 'tab_already_opened';

    // Check if sessionStorage has a tab ID (i.e., it's a duplicate tab)test
    if (sessionStorage.getItem(uniqueKey)) {
      // console.warn('Duplicate tab detected, clearing session storage');
      sessionStorage.clear();
    } else {
      // Assign a unique ID to the current tab
      const newTabId = Date.now().toString();
      sessionStorage.setItem(uniqueKey, newTabId);
    }

    // Check if another tab was already opened using LocalStorage (copy-pasting case)
    if (localStorage.getItem(tabOpenedKey)) {
      // console.warn('New tab detected using copy-paste, closing this tab');
      alert('Another session is already active. This tab will now close.');
      window.close(); // Attempt to close the new tab
    } else {
      // Mark that a tab is already open
      localStorage.setItem(tabOpenedKey, 'true');
    }

    // Remove unique tab ID and tab open marker when tab is closed
    window.addEventListener('beforeunload', () => {
      sessionStorage.removeItem(uniqueKey);
      localStorage.removeItem(tabOpenedKey);
    });

  }


}
