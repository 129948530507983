import { Component, Input, ViewEncapsulation, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NavService, Menu } from '../../service/nav.service';
import { MyService } from 'src/app/myservice';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit {
  public menuItems: Menu[] = [];
  public filteredMenuData: Menu[] = [];
  public url: string | ArrayBuffer | null = null;



  constructor(private router: Router, public navServices: NavService, private myService: MyService) {
    const user = JSON.parse(sessionStorage.getItem('User') || '{}');
    // console.log(user)
    this.user = user

    this.myService.UserSetting.subscribe(data => {
      this.role = data;
      // console.log(this.role)
    });
    this.navServices.items.subscribe((menuItems) => {
      // Filter the menu items based on roles
      this.filteredMenuData = this.filterMenuData(menuItems, this.role);

      // Initialize active menu state on navigation events
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.updateActiveMenuState(event.url);
        }
      });
    });

  }
  role: number[] = [];
  selectedItem: number | null = null;
  selectedChild: any | null = null;

  user: any = []

  ngOnInit(): void {


  }

  /**
   * Filter menu items based on user roles
   */
//   filterMenuData(menuItems: any[], role: any[]): any[] {
//     console.log(role)
//     console.log(menuItems)
//     return menuItems.filter((menuItem) => {
      
//        console.log("Processing Menu Item:", menuItem);
//       if (menuItem.pageid && role.includes(menuItem.pageid)) {
//         console.log("Including Menu Item (Top-Level with pageid and role match):", menuItem);
//         return true; 
//       }
  
    
//       if (menuItem.children && menuItem.children.length > 0) {
//         const validChildren = menuItem.children.filter(child => child.pageid && role.includes(child.pageid));
  
     
//          console.log("Valid Children with pageid and role match:", validChildren);
  
//         if (validChildren.length > 0) {
         
//            console.log("Including Sub-Menu Item:", menuItem);
//           return true;
//         } else {
//            console.log("Excluding Sub-Menu Item (No Valid Children with role match):", menuItem);
//         }
//       } else {
      
//         console.log("Excluding Menu Item (No pageid or valid children with role match):", menuItem);
//       }
  
//       return false; 
//     });
//   }
filterMenuData(menuItems: any[], role: number[]): any[] {
  return menuItems
      .map(menuItem => {
          if (menuItem.children && menuItem.children.length > 0) {
              const validChildren = menuItem.children.filter(child => role.includes(child.pageid));

              if (validChildren.length > 0) {
                  return {
                      ...menuItem,
                      children: validChildren 
                  };
              }
          } else if (menuItem.pageid && role.includes(menuItem.pageid)) {
              return menuItem;
          }
          return null; 
      })
      .filter(Boolean); 
}


  /**
   * Update active navigation state
   */
  private updateActiveMenuState(url: string): void {
    this.filteredMenuData.forEach((items) => {
      items.active = items.path === url;
      if (!items.children) return;

      items.children.forEach((subItems) => {
        subItems.active = subItems.path === url;
        if (!subItems.children) return;

        subItems.children.forEach((subSubItems) => {
          subSubItems.active = subSubItems.path === url;
        });
      });
    });
  }

  /**
   * Toggle menu item active state
   */
  public toggletNavActive(item: Menu): void {
    if (!item.active) {
      this.filteredMenuData.forEach((menuItem) => {
        menuItem.active = false;
        if (menuItem.children) {
          menuItem.children.forEach((subMenuItem) => (subMenuItem.active = false));
        }
      });
    }
    item.active = !item.active;
  }

  /**
   * Handle file upload
   */
  public readUrl(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files || input.files.length === 0) return;

    const mimeType = input.files[0].type;
    if (!mimeType.match(/image\/*/)) return;

    const reader = new FileReader();
    reader.readAsDataURL(input.files[0]);
    reader.onload = () => {
      this.url = reader.result;
    };
  }
}
