

<div class="container-fluid">
    <div class="page-header">
      <div class="row">
        <div class="col-lg-6">
          <div class="page-header-left">
            <h3>
              HOME SETTING
    
            </h3>
          </div>
        </div>
      
      </div>
    </div>
  </div>

<div class="container-fluid">
    <div class="card" id="upload_banner">
        <div class="card-body">
            <div class="row">
                <h4> <strong class="text-underline text-uppercase m-25">Upload Banner Image </strong> </h4>
                <div class="col-lg-6" *ngFor="let data of Bannerpayload">
                    <div class="card-body" style="padding: 10px;">
                        <div class="dropzone-custom">
                            <ngx-dropzone class="dropzone-border " style="justify-content: center;"
                                (change)="onSelect($event, data)">
                                <ngx-dropzone-label>
                                    <div class="dz-message needsclick">
                                        <i class="fa fa-cloud-upload"></i>
                                        <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                    </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-preview *ngIf="data.url" [removable]="true" (removed)="data.url = null">
                                    <ngx-dropzone-label>
                                        <img [src]="data.url" alt="Uploaded Image" class="preview-image" />
                                    </ngx-dropzone-label>
                                </ngx-dropzone-preview>
                            </ngx-dropzone>

                        </div>
                    </div>
                    <div class="row mb-2" style="padding: 8px;">
                        <div class="col-lg-3">
                            <label for="smallContent" class="text-nowrap" style="margin-top: 5px;"><strong>Small
                                    Content</strong></label>
                        </div>
                        <div class="col-lg-9">
                            <input type="text" id="smallContent" [(ngModel)]="data.jsonData.desc" class="form-control">
                        </div>
                    </div>
                    <div class="row mb-3" style="padding: 8px;">
                        <div class="col-lg-3 d-flex">
                            <label for="textcolor1" style="margin-top: 5px;"><strong>Text Colour</strong></label>
                        </div>
                        <div class="col-lg-2">
                            <div style="height: 20px;width: 50px; margin: 10px 10px 10px 10px;"
                                [ngStyle]="{'background-color':data.jsonData.f1_color}">
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <input type="text" id="textcolor1" [(ngModel)]="data.jsonData.f1_color"
                                class="form-control">
                        </div>
                    </div>
                    <div class="row mb-3" style="padding: 8px;">
                        <div class="col-lg-3">
                            <label for="companyName" class="text-nowrap " style="margin-top: 5px;"> <strong>Company
                                    Name</strong></label>
                        </div>
                        <div class="col-lg-9">
                            <input type="text" id="companyName" [(ngModel)]="data.jsonData.name" class="form-control">
                        </div>
                    </div>
                    <div class="row mb-3" style="padding: 8px;">

                        <div class="col-lg-3 d-flex">
                            <label for="textcolor2" style="margin-top: 5px;"><strong>Text Colour</strong></label>
                            <!-- <div style="height: 20px;width: 50px; margin-left: 10px;"
                                [ngStyle]="{'background-color':data.f1_color}">
    
                            </div> -->
                        </div>
                        <div class="col-lg-2">
                            <div style="height: 20px;width: 50px; margin-left: 10px;"
                                [ngStyle]="{'background-color':data.jsonData.f2_color}">
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <input type="text" id="textcolor2" [(ngModel)]="data.jsonData.f2_color"
                                class="form-control">
                        </div>
                    </div> <br>
                    <div class="col-lg-7">
                        <button class="btn btn-secondary float-md-end " (click)="SaveHomeSetting(data) ">Save</button>
                        <!-- <button class="btn btn-secondary float-md-end " >Save</button> -->
                    </div>
                </div> <br>
            </div><br>
        </div>
    </div>



    <div class="card" id="upload_advertisement">
        <div class="card-body">
            <div class="row">
                <div class="col-lg-6" *ngFor="let data of AdvertisementPayload">
                    <h4> <strong class="text-underline text-uppercase m-25">Upload Advertisement Image </strong>
                    </h4>

                    <div class="card-body">
                        <div class="dropzone-custom">
                            <ngx-dropzone class="dropzone-border" style="justify-content: center;"
                                (change)="onSelect($event, data)">
                                <ngx-dropzone-label>
                                    <div class="dz-message needsclick">
                                        <i class="fa fa-cloud-upload"></i>
                                        <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                    </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-preview *ngIf="data.url" [removable]="true" (removed)="data.image = null">
                                    <ngx-dropzone-label>

                                        <img [src]="data.url" alt="Uploaded Image" class="preview-image" />
                                    </ngx-dropzone-label>
                                </ngx-dropzone-preview>
                            </ngx-dropzone>
                        </div>
                        <div class="row mt-3">
                            <div class="col-lg-4">
                                <label for="smallContent"><strong>Offer Small Content</strong></label>
                            </div>
                            <div class="col-lg-8 mb-3">
                                <input type="text" id="smallContent" class="form-control"
                                    [(ngModel)]="data.jsonData.desc">
                            </div>
                            <div class="col-lg-4 d-flex">
                                <label for="textcolor1"><strong>Text Colour</strong></label>
                                <div style="height: 20px;width: 50px; margin-left: 10px;"
                                    [ngStyle]="{'background-color':data.jsonData.f2_color}">

                                </div>
                            </div>
                            <div class="col-lg-8 mb-3">
                                <input type="text" id="textcolor1" class="form-control"
                                    [(ngModel)]="data.jsonData.f2_color">
                            </div>

                            <div class="col-lg-4">
                                <label for="offercontent"><strong>Offer Content</strong></label>
                            </div>
                            <div class="col-lg-8 mb-3">
                                <input type="text" id="offercontent" class="form-control"
                                    [(ngModel)]="data.jsonData.name">
                            </div>
                            <div class="col-lg-4 d-flex">
                                <label for="textcolor2"><strong>Text Colour</strong></label>
                                <div style="height: 20px;width: 50px; margin-left: 10px;"
                                    [ngStyle]="{'background-color':data.jsonData.f1_color}">

                                </div>
                            </div>
                            <div class="col-lg-8 mb-3">
                                <input type="text" id="textcolor2" class="form-control"
                                    [(ngModel)]="data.jsonData.f1_color">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7">
                        <button class="btn btn-secondary float-md-end " (click)="SaveHomeSetting(data) ">Save</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="card" *ngIf="BackgroundPayload.length>0" id="upload_banner">
        <div class="card-body">
            <div class="row">
                <h4> <strong class="text-underline text-uppercase m-25">Upload Background Image</strong> </h4>
                <div class="col-lg-6">
                    <div class="card-body">
                        <div class="dropzone-custom">
                            <ngx-dropzone class="dropzone-border" style="justify-content: center;"
                                (change)="onSelect($event, BackgroundPayload[0])">
                                <ngx-dropzone-label>
                                    <div class="dz-message needsclick">
                                        <i class="fa fa-cloud-upload"></i>
                                        <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                    </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-preview *ngIf="BackgroundPayload[0].url" [removable]="true"
                                    (removed)="BackgroundPayload[0].image = null">
                                    <ngx-dropzone-label>

                                        <img [src]="BackgroundPayload[0].url" alt="Uploaded Image"
                                            class="preview-image" />
                                    </ngx-dropzone-label>
                                </ngx-dropzone-preview>
                            </ngx-dropzone>


                        </div>
                    </div>
                </div> <br>
                <div class="col-lg-6" *ngIf="BackgroundPayload.length>0">
                    <div class="row mt-3">
                        <div class="col-lg-4">
                            <label for="smallContent"><strong>Small Content</strong></label>
                        </div>
                        <div class="col-lg-8 mb-3">
                            <input type="text" id="smallContent" class="form-control"
                                [(ngModel)]="BackgroundPayload[0].jsonData.desc">
                        </div>
                        <div class="col-lg-4 d-flex">
                            <label for="textcolor1"><strong>Text Colour</strong></label>
                            <div style="height: 20px;width: 50px; margin-left: 10px;"
                                [ngStyle]="{'background-color':BackgroundPayload[0].jsonData.f2_color}">

                            </div>
                        </div>
                        <div class="col-lg-8 mb-3">
                            <input type="text" id="textcolor1" class="form-control"
                                [(ngModel)]="BackgroundPayload[0].jsonData.f2_color">
                        </div>

                        <div class="col-lg-4">
                            <label for="offercontent"><strong>Offer Content</strong></label>
                        </div>
                        <div class="col-lg-8 mb-3">
                            <input type="text" id="offercontent" class="form-control"
                                [(ngModel)]="BackgroundPayload[0].jsonData.name">
                        </div>
                        <div class="col-lg-4 d-flex">
                            <label for="textcolor2"><strong>Text Colour</strong></label>
                            <div style="height: 20px;width: 50px; margin-left: 10px;"
                                [ngStyle]="{'background-color':BackgroundPayload[0].jsonData.f1_color}">

                            </div>
                        </div>
                        <div class="col-lg-8 mb-3">
                            <input type="text" id="textcolor2" class="form-control"
                                [(ngModel)]="BackgroundPayload[0].jsonData.f1_color">
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-lg-7">
                <button class="btn btn-secondary float-md-end "
                    (click)="SaveHomeSetting(BackgroundPayload[0])">Save</button>
            </div>
        </div>

    </div>



    <div class="card" *ngIf="SmallAdvImgPayload.length" id="upload_smalladv">
        <div class="card-body">
            <div class="row" style="justify-content: center;">


                <div class="col-lg-6">
                    <h4> <strong class="text-underline text-uppercase m-25">Upload Small Advertisement Image </strong>
                    </h4>

                    <div class="card-body" style="padding: 10px;">
                        <div class="dropzone-custom">
                            <ngx-dropzone class="dropzone-border" style="justify-content: center;"
                                (change)="onSelect($event, SmallAdvImgPayload[0])">
                                <ngx-dropzone-label>
                                    <div class="dz-message needsclick">
                                        <i class="fa fa-cloud-upload"></i>
                                        <h4 class="mb-0 f-w-600">Drop files here or click to upload.</h4>
                                    </div>
                                </ngx-dropzone-label>
                                <ngx-dropzone-preview *ngIf="SmallAdvImgPayload[0].url" [removable]="true"
                                    (removed)="SmallAdvImgPayload[0].image = null">
                                    <ngx-dropzone-label>

                                        <img [src]="SmallAdvImgPayload[0].url" alt="Uploaded Image"
                                            class="preview-image" />
                                    </ngx-dropzone-label>
                                </ngx-dropzone-preview>
                            </ngx-dropzone>
                        </div>
                    </div>
                    <!-- 
                    <div class="row mb-2" style="padding: 8px;">
                        <div class="col-lg-3">
                            <label for="smallContent" class="text-nowrap" style="margin-top: 5px;"><strong>Small
                                    Content</strong></label>
                        </div>
                        <div class="col-lg-9">
                            <input type="text" id="smallContent" class="form-control"
                                [(ngModel)]="SmallAdvImgPayload[0].jsonData.desc">
                        </div>
                    </div>
                    <div class="row mb-3" style="padding: 8px;">
                        <div class="col-lg-3 d-flex">
                            <label for="textcolor1" style="margin-top: 5px;"><strong>Text Colour</strong></label>
                        </div>
                        <div class="col-lg-2">
                            <div style="height: 20px;width: 50px; margin: 10px 10px 10px 10px;"
                                [ngStyle]="{'background-color':SmallAdvImgPayload[0].jsonData.f1_color}">
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <input type="text" id="textcolor1" class="form-control"
                                [(ngModel)]="SmallAdvImgPayload[0].jsonData.f1_color">
                        </div>
                    </div>
                    <div class="row mb-3" style="padding: 8px;">
                        <div class="col-lg-3">
                            <label for="companyName" class="text-nowrap " style="margin-top: 5px;"> <strong>Company
                                    Name</strong></label>
                        </div>
                        <div class="col-lg-9">
                            <input type="text" id="companyName" class="form-control"
                                [(ngModel)]="SmallAdvImgPayload[0].jsonData.name">
                        </div>
                    </div>
                    <div class="row mb-3" style="padding: 8px;">

                        <div class="col-lg-3 d-flex">
                            <label for="textcolor1" style="margin-top: 5px;"><strong>Text Colour</strong></label>
                        </div>
                        <div class="col-lg-2">
                            <div style="height: 20px;width: 50px; margin: 10px 10px 10px 10px;"
                                [ngStyle]="{'background-color':SmallAdvImgPayload[0].jsonData.f2_color}">
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <input type="text" id="textcolor2" class="form-control "
                                [(ngModel)]="SmallAdvImgPayload[0].jsonData.f2_color">
                        </div>
                    </div> <br> -->
                    <div class="d-flex justify-content-center">
                        <button class="btn btn-secondary " (click)="SaveHomeSetting(SmallAdvImgPayload[0])">
                            Save</button>
                        <!-- <button class="btn btn-secondary float-md-end " >Save</button> -->
                    </div>
                </div>
            </div>
        </div>

    </div>








    <div style="display: none;    z-index: 999;
      position: fixed;" id="bannerload">
        <div class="bouncing-loader">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>